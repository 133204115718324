<div class="main-navigation">
    <div class="main-navigation__logo">
        <img class="main-navigation__logo-img" src="assets/logo/home-logo.png" alt="Merkur Casino">
    </div>
    <div class="main-navigation__container">
        <p class="main-navigation__container--update-text" [innerHTML]="'software_update_message' | translate"></p>
    </div>
</div>
<div class="age-restriction">
    <span class="age-restriction__text" [innerHTML]="'age_restriction' | translate"></span>
    <img class="age-restriction__logo" src="assets/icons/18+.svg">
</div>
