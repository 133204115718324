import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/shared/modules/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { VsCheckboxComponent } from '@app/shared/components/vs-checkbox/vs-checkbox.component';
import { FormStepperComponent } from './components/form-stepper/form-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { VsInputComponent } from './components/vs-input/vs-input.component';
import { VsSelectComponent } from './components/vs-select/vs-select.component';
import { VsDatePickerComponent } from './components/vs-date-picker/vs-date-picker.component';
import { VsAutocompleteSelectComponent } from './components/vs-autocomplete-select/vs-autocomplete-select.component';
import { VsAddressComponent } from './components/vs-address/vs-address.component';
import { KeyboardComponent } from './components/keyboard/keyboard.component';
import { NotificationModalComponent } from '@app/shared/components/notifications/notification-modal.component';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogInfoComponent } from './components/dialog-info/dialog-info.component';
import { VsValidationErrorsComponent } from './components/vs-validation-errors/vs-validation-errors.component';
import { VsPrefixNameFieldComponent } from './components/vs-prefix-name-field/vs-prefix-name-field.component';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { VsInputCounterComponent } from './components/vs-input-counter/vs-input-counter.component';
import { VsSliderComponent } from './components/vs-slider/vs-slider.component';
import { VsPhoneNumberComponent } from './components/vs-phone-number/vs-phone-number.component';
import { PopupComponent } from './components/popup/popup.component';
import { VsContinueButtonComponent } from './components/vs-continue-button/vs-continue-button.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { PasswordSuggestionCardComponent } from './components/password-suggestion-card/password-suggestion-card.component';
import { BanknoteBoxComponent } from './components/banknote-box/banknote-box.component';
import { InfoTestLabelComponent } from './components/info-test-label/info-test-label.component';
import { DialogPopupComponent } from './components/dialog-popup/dialog-popup.component';
import { ScreenTimeoutPopupComponent } from './components/screen-timeout-popup/screen-timeout-popup.component';
import { CasinoOffersComponent } from './components/casino-offers/casino-offers.component';
import { VsButtonComponent } from './components/vs-button/vs-button.component';
import { VsPhoneVerificationComponent } from './components/vs-phone-verification/vs-phone-verification.component';
import { AutoIdentIframeComponent } from '@app/modules/registration/components/auto-ident-iframe/auto-ident-iframe.component';
import { TickerComponent } from './components/ticker/ticker.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { SentryService } from './services/sentry.service';
import { NgOtpInputModule } from 'ng-otp-input';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { VsPhoneOrEmailComponent } from './components/vs-phone-or-email/vs-phone-or-email.component';
import { VsBackButtonComponent } from './components/vs-back-button/vs-back-button.component';

register();

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    NotificationModalComponent,
    VsInputComponent,
    VsSelectComponent,
    VsDatePickerComponent,
    VsCheckboxComponent,
    FormStepperComponent,
    VsAutocompleteSelectComponent,
    VsAddressComponent,
    KeyboardComponent,
    BaseComponent,
    DialogInfoComponent,
    VsValidationErrorsComponent,
    VsPrefixNameFieldComponent,
    FilterArrayPipe,
    VsInputCounterComponent,
    VsSliderComponent,
    VsPhoneNumberComponent,
    PopupComponent,
    VsContinueButtonComponent,
    SafeUrlPipe,
    InfoBoxComponent,
    PasswordSuggestionCardComponent,
    BanknoteBoxComponent,
    InfoTestLabelComponent,
    DialogPopupComponent,
    ScreenTimeoutPopupComponent,
    CasinoOffersComponent,
    VsButtonComponent,
    VsPhoneVerificationComponent,
    AutoIdentIframeComponent,
    TickerComponent,
    SafeHtmlPipe,
    VsPhoneOrEmailComponent,
    VsBackButtonComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    CdkStepperModule,
    TranslateModule,
    NgOtpInputModule
  ],
  exports: [
    ReactiveFormsModule,
    MaterialModule,
    VsInputComponent,
    VsSelectComponent,
    VsDatePickerComponent,
    VsCheckboxComponent,
    FormStepperComponent,
    VsAutocompleteSelectComponent,
    VsAddressComponent,
    KeyboardComponent,
    TranslateModule,
    VsPrefixNameFieldComponent,
    FilterArrayPipe,
    VsInputCounterComponent,
    VsSliderComponent,
    VsPhoneNumberComponent,
    VsContinueButtonComponent,
    SafeUrlPipe,
    InfoBoxComponent,
    BanknoteBoxComponent,
    InfoTestLabelComponent,
    CasinoOffersComponent,
    VsButtonComponent,
    VsPhoneVerificationComponent,
    AutoIdentIframeComponent,
    TickerComponent,
    SafeHtmlPipe,
    VsPhoneOrEmailComponent,
    VsBackButtonComponent
  ],
  providers: [SentryService]
})
export class SharedModule {}
