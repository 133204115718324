export interface PayladoTransactionInfo {
    amount: number | string
    dateTime: string
    transactionId: number | string
    terminalId?: number
    transactionType: TransactionType
}

export type TransactionType = 'PAY-IN' | 'PAY-OUT' | 'PAY-OUT-FAILED';

export enum TransactionTypeEnum {
    PAY_IN = 'PAY-IN',
    PAY_OUT = 'PAY-OUT',
    PAY_OUT_FAILED = 'PAY-OUT-FAILED'
}