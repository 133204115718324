import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShellComponent } from './components/app-shell/app-shell.component';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { AccountInfoComponent } from '../customer/components/account-info/account-info.component';
import { DashboardComponent } from '@app/modules/layout/components/dashboard/dashboard.component';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';
import { ThemeSwitcherDialogComponent } from './components/theme-switcher-dialog/theme-switcher-dialog.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { HeaderModule } from './components/header/header.module';

@NgModule({
  declarations: [
    AppShellComponent,
    AccountInfoComponent,
    DashboardComponent,
    LangSwitcherComponent,
    WelcomePageComponent,
    ThemeSwitcherComponent,
    ThemeSwitcherDialogComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule, MatGridListModule, HeaderModule]
})
export class LayoutModule {}
