import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, filter, repeat, repeatWhen, switchMap, take, takeWhile } from 'rxjs/operators';
import { StepThreeUserData } from '../models/auto-ident-data';
import {
  FinishVerificationResponse,
  InitVerificationResponse,
  AutoIdentUserResponseData
} from '../types/auto-ident-type';
import { AUTO_IDENT_SONIO, GERMANY_ALPHA_ISO_2, GERMANY_ALPHA_ISO_3 } from '../utils/constants';
import { FeatureFlagsResponse } from '../types/feature-flags-type';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root'
})
export class AutoIdentService {
  private environment: any;

  constructor(
    public http: HttpClient,
    public config: ProjectConfigService,
    public registrationService: RegistrationService
  ) {
    this.environment = this.config.getConfig();
  }

  get<T>(url = '', options?: any): Observable<any> {
    return this.http.get<T>(url, options);
  }

  post<T>(url = '', payload = {}): Observable<any> {
    return this.http.post<T>(url, { ...payload });
  }

  initAutoVerification(payload = {}): Observable<InitVerificationResponse> {
    payload = { ...payload };

    return this.getFeatureFlag().pipe(
      switchMap(response => {
        let apiUrl: string;
        if (response.isEnabled === false) {
          apiUrl = `${this.environment.octopusApiUrl}/${this.environment.initAutoIdent}`;
        } else {
          apiUrl = `${this.environment.hostUrlDolphin}/${this.environment.dolphinInitAutoIdent}`;
        }

        return this.post<InitVerificationResponse>(`${apiUrl}`, payload);
      })
    );
  }

  finishAutoVerification(payload = {}): Observable<FinishVerificationResponse> {
    payload = {
      ...payload
    };
    return this.getFeatureFlag().pipe(
      switchMap(response => {
        let apiUrl: string;

        if (!response.isEnabled) {
          apiUrl = `${this.environment.octopusApiUrl}/${this.environment.finishAutoIdent}`;
        } else {
          apiUrl = `${this.environment.hostUrlDolphin}/${this.environment.dolphinFinishAutoIdent}`;
        }

        return this.post<FinishVerificationResponse>(`${apiUrl}`, payload);
      })
    );
  }

  getSessionResult(param = ''): Observable<AutoIdentUserResponseData> {
    const sessionID = param;
    const { SESSION_STATUS_COMPLETED, SESSION_REQUEST_REPEAT_COUNT, SESSION_REQUEST_DELAY } = AUTO_IDENT_SONIO;
    let repeatCounter = 0;

    return this.getFeatureFlag().pipe(
      switchMap(featureFlagSonio => {
        const apiUrl = !featureFlagSonio.isEnabled
          ? `${this.environment.octopusApiUrl}/${this.environment.getSessionAutoIdent}/${sessionID}`
          : `${this.environment.hostUrlDolphin}/${this.environment.dolphinGetSessionAutoIdent}/${sessionID}`;

        return this.get<AutoIdentUserResponseData>(apiUrl).pipe(
          delay(SESSION_REQUEST_DELAY),
          repeatWhen(notifications =>
            notifications.pipe(
              delay(SESSION_REQUEST_DELAY),
              takeWhile(() => repeatCounter < SESSION_REQUEST_REPEAT_COUNT)
            )
          ),
          filter(response => {
            repeatCounter++;
            return response && response.status === SESSION_STATUS_COMPLETED;
          }),
          take(1), // Take the first successful response
          catchError(error => {
            console.error('An error occurred:', error);
            return throwError(error);
          })
        );
      })
    );
  }

  private getFeatureFlag(): Observable<FeatureFlagsResponse> {
    return this.get<FeatureFlagsResponse>(
      `${this.environment.hostUrlDolphin}/${this.environment.dolphinVisionFeatureFlags}/soniov4`
    );
  }

  formatResultData(response: AutoIdentUserResponseData): StepThreeUserData {
    const responseData = response && response.data ? response.data : {};
    const isGermanyDocument =
      response?.data?.issuingCountry?.toLocaleUpperCase() === GERMANY_ALPHA_ISO_3 ||
      response?.data?.issuingCountry?.toLocaleUpperCase() === GERMANY_ALPHA_ISO_2;
    let userData: StepThreeUserData = {
      address: {
        street: '',
        house_number: '',
        city: '',
        zip_code: '',
        country: 'DEU'
      },
      last_name: '',
      birth_name: '',
      birth_date: '',
      place_of_birth: '',
      gender: '',
      first_name: '',
      country_of_birth: '',
      citizenship: '',
      occupation: ''
    };

    Object.keys(responseData).forEach(key => {
      const value = this.removeWhiteSpace(responseData[key]);
      switch (key) {
        case 'firstName':
          userData.first_name = value;
          break;
        case 'lastName':
          userData.last_name = value;
          break;
        case 'gender':
          const gender = this.mapGenderVariations(value);
          userData.gender = gender;
          break;
        case 'dob':
          userData.birth_date = value;
          break;
        case 'placeOfBirth':
          userData.place_of_birth = value;
          break;
        case 'nationality':
          userData.citizenship = value;
          break;
        case 'street':
          userData.address.street = isGermanyDocument ? value : '';
          break;
        case 'houseNumber':
          userData.address.house_number = isGermanyDocument ? value : '';
          break;
        case 'city':
          userData.address.city = isGermanyDocument ? value : '';
          break;
        case 'postalCode':
          userData.address.zip_code = isGermanyDocument ? value : '';
          break;
        default:
          break;
      }
    });

    const isAddressComplete = Object.values(userData.address).every(field => field !== '' && field !== null);
    if (isAddressComplete && isGermanyDocument) {
      this.registrationService.updateSonioAddressStatus(true);
    } else {
      this.registrationService.updateSonioAddressStatus(false);
    }

    return userData;
  }

  mapGenderVariations(gender: string): string {
    if (typeof gender != 'string') return '';

    const _gender = gender.toLowerCase();
    switch (_gender) {
      case 'm':
      case 'male':
      case 'mr':
      case 'herr':
        return 'male';
      case 'f':
      case 'female':
      case 'ms':
      case 'mrs':
      case 'frau':
        return 'female';
      default:
        return '';
    }
  }

  removeWhiteSpace(value: string): string {
    if (typeof value != 'string') return value;

    return value.replace(/  +/g, ' ').trim();
  }
}
