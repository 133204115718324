<mat-form-field appearance="outline"
                [formGroup]="registrationForm"
                [ngClass]="{'valid-input': formControl.valid}"
                floatLabel="never">
    <mat-select [formControlName]="config.key" placeholder="{{config.label | translate}}">
        <mat-option *ngFor="let item of config.options" [value]="item.key">
            {{item.withTranslation ? (item.value | translate) : item.value}}
        </mat-option>
    </mat-select>
    <mat-error
            *ngIf="formControl.hasError('required')">{{config.label | translate}} {{'error_is_required' | translate}}</mat-error>
</mat-form-field>
