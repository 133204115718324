import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-vs-back-button',
  templateUrl: './vs-back-button.component.html',
  styleUrls: ['./vs-back-button.component.scss']
})
export class VsBackButtonComponent {
  @Output() public clickEvent: EventEmitter<void> = new EventEmitter();
  @Input() textButton = __('back_button');
  @Input() isButtonInactive: boolean;
  @Input() isSubmitting: boolean;
  @Input() isDisabled: boolean;
}
