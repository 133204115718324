<div class="popup">
    <button *ngIf="data && data.closeBtn"
        mat-icon-button class="popup__close-btn"  mat-dialog-close>
        <mat-icon class="popup__close-btn--icon">close</mat-icon>
    </button>
    <div mat-dialog-content class="loading-popup">
        <p [innerHTML]="data.content | translate"></p>
        <mat-spinner *ngIf="data.spinner" [diameter]="150"></mat-spinner>
        <img style="height: 150px;" *ngIf="data.imgSrc" [src]="data.imgSrc" alt="printer">
        <button class="retry-button" *ngIf="data.retryButton" (click)="retryRequest()">Retry</button>
    </div>
</div>
