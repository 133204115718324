<div *ngIf="data.message" class="notification-message__content">
    <span class="notification-message__text" [innerHTML]="data.message | translate"></span>

    <div class="notification-message__actions">

        <button *ngIf="data.dismiss"
                mat-button
                mat-raised-button
                color="primary"
                class="notification-message__action"
                (click)="snackBarRef.dismiss()"
                [innerHTML]="data.dismiss | translate">
        </button>

        <button
            *ngIf="data.action"
            mat-button
            mat-raised-button
            color="accent"
            class="notification-message__action"
            (click)="snackBarRef.dismissWithAction()"
            [innerHTML]="data.action | translate">
        </button>

    </div>
</div>
