import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ProjectConfigService } from './project-config.service';
import { TerminalData } from '../models/terminal-data';
import { NotificationService } from './notification.service';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { ERROR_TERMINAL_INACTIVE } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class TerminalDataService {
  private environment: any;
  private terminalData: TerminalData | Record<string, never> = {};
  public isTerminalDataSet = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private config: ProjectConfigService,
    private notificationService: NotificationService
  ) {
    this.environment = this.config.getConfig();
  }

  getTerminalApiData(): Observable<TerminalData> {
    // return this.http.get<TerminalData>(`${this.environment.hostUrl}/${this.environment.terminal}`);
    return this.http.get<TerminalData>(`${this.environment.hostUrlDolphin}/${this.environment.terminalDolphin}`);
  }

  getTerminalLastOnlineState(): Observable<number> {
    // return this.http.get<number>(`${this.environment.hostUrl}/${this.environment.terminalLastOnlineState}`);
    return this.http.get<number>(
      `${this.environment.hostUrlDolphin}/${this.environment.terminalLastOnlineStateDolphin}`
    );
  }

  async fetchTerminalData(): Promise<void> {
    try {
      const data = await this.getTerminalApiData().toPromise();
      const { id } = data;
      if (!id) {
        throw new Error('Terminal id is missing');
      }

      this.setTerminalData(data);
    } catch (e) {
      const errorMessage = __('terminal_data_is_missing');
      this.notificationService.showMessage(errorMessage, 'error', false);
      if (e.error && e.error.errorCode === 'terminal_not_active') {
        throw ERROR_TERMINAL_INACTIVE;
      }
    }
  }

  setTerminalData(data: TerminalData): void {
    this.terminalData = data;
    this.isTerminalDataSet.next(true);
  }

  getTerminalData(): TerminalData | Record<string, never> {
    return this.terminalData;
  }
}
