import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormInput } from '@app/shared/models/form-input';
import { LoqateService } from '@app/modules/registration/services/loqate.service';
import { LoqateFindItem } from '@app/modules/registration/models/loqate-find-item';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { HelperService } from '@app/shared/services/helper.service';
import { checkWhitespace } from '@app/modules/registration/customValidators';

@Component({
  selector: 'app-vs-autocomplete-select',
  templateUrl: './vs-autocomplete-select.component.html',
  styleUrls: ['./vs-autocomplete-select.component.scss']
})
export class VsAutocompleteSelectComponent implements OnInit, OnDestroy {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;

  allSubscriptions: Subscription[] = [];

  autoCompleteControl = new FormControl('');
  filteredOptions: LoqateFindItem[];
  selectedContainerId = '';
  selectedSearchText = '';

  constructor(private loqateService: LoqateService, private keyboardService: HelperService) {}

  ngOnInit(): void {
    const validators = [];
    if (this.config.required) {
      validators.push(Validators.required);
    }

    this.autoCompleteControl.setValidators(validators);
    // attach autocomplete field as tmp field to handle errors from parent form
    this.registrationForm.addControl('tmpSearch', this.autoCompleteControl);

    this.allSubscriptions.push(
      this.autoCompleteControl.valueChanges
        .pipe(filter((rawSearch: string) => rawSearch && rawSearch.length > 2))
        .subscribe(search => {
          const containerId = search !== this.selectedSearchText ? '' : this.selectedContainerId;
          this.loqateService
            .findLoqateRequest({
              containerId,
              text: search
            })
            .subscribe(response => {
              this.filteredOptions = [...response];
              this.autocompleteTrigger.openPanel();
            });
        })
    );
  }

  // fetch details data for the selected address
  loqateSelect(selectedItem: LoqateFindItem): void {
    if (!selectedItem.isAddress) {
      this.selectedSearchText = selectedItem.text;
      this.selectedContainerId = selectedItem.type === 'Street' ? selectedItem.id : '';
    } else {
      this.loqateService.getLoqateRequest({ id: selectedItem.id });
      this.autocompleteTrigger.closePanel();
    }
  }

  resetSearch(): void {
    this.autoCompleteControl.reset();
    this.filteredOptions = [];
    this.selectedContainerId = '';
    this.selectedSearchText = '';
    this.keyboardService.clearKeyboardInput(true);
  }

  ngOnDestroy(): void {
    for (const subscription of this.allSubscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }

  onFocusInOut(): void {
    this.keyboardService.setKeyboardInputConfig(this.config);
  }
}
