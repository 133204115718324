<form [formGroup]="phoneNumberForm" class="phone-email">
  <mat-form-field
    class="phone-number__dial-code"
    appearance="outline"
    [ngClass]="{ 'valid-input': phoneNumberForm.get('dialCode').valid, 'disabled-select': isEmail }"
  >
    <mat-select formControlName="dialCode" [disabled]="isEmail">
      <mat-select-trigger>
        <span class="dial-code-option__placeholder">+{{ selectedDialCode.code }}</span>
        <span class="dial-code-option__placeholder">({{ selectedDialCode.value.toUpperCase() }})</span>
      </mat-select-trigger>
      <mat-option *ngFor="let item of dialCodes" [value]="item?.code">
        <span class="dial-code-option country-{{ item?.key }}"></span>
        <span class="dial-code-option__text">{{ item.value }} (+{{ item?.code }})</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="phone-number__number"
    appearance="standard"
    [ngClass]="{ 'valid-input': phoneNumberForm.get('number').valid }"
  >
    <mat-label>{{ config.label | translate }}</mat-label>
    <input
      matInput
      #phone_number_input
      autocomplete="off"
      formControlName="number"
      [type]="config.type"
      (blur)="onBlur()"
      (focusin)="onFocusInOut('in', $event)"
      (focusout)="onFocusInOut('out', $event)"
      [id]="config.key"
    />
    <div
      *ngIf="phoneNumberForm.get('number').value"
      style="cursor: pointer"
      matSuffix
      aria-label="Clear"
      (click)="resetInput()"
    >
      <mat-icon>close</mat-icon>
    </div>
    <mat-error
      *ngIf="phoneNumberForm.get('number').errors?.required && !showValidationLabels"
      [innerHTML]="(config.label | translate) + ' ' + ('error_is_required' | translate)"
    ></mat-error>
    <mat-error
      *ngIf="phoneNumberForm.get('dialCode').errors?.required && !showValidationLabels"
      [innerHTML]="getErrorMessage('invalidPhoneNumber') | translate"
    ></mat-error>
    <mat-error
      *ngIf="
        phoneNumberForm.get('number').errors?.phonePatternInvalid && !phoneNumberForm.get('number').errors?.required
      "
      [innerHTML]="getErrorMessage('phonePatternInvalid') | translate"
    ></mat-error>
    <mat-error
      *ngIf="phoneNumberForm.get('number').errors?.emailOrNumberExists"
      [innerHTML]="getErrorMessage('emailOrNumberExists') | translate"
    ></mat-error>
    <mat-error
      *ngIf="phoneNumberForm.get('number').errors?.email"
      [innerHTML]="getErrorMessage('email') | translate"
    ></mat-error>
    <mat-error
      *ngIf="phoneNumberForm.get('number').errors?.incorrect"
      [innerHTML]="getErrorMessage('incorrect') | translate"
    ></mat-error>
  </mat-form-field>
</form>
