import { Injectable } from '@angular/core';
import { captureException, SeverityLevel } from '@sentry/angular-ivy';

@Injectable()
export class SentryService {
  constructor() {}

  sendErrorToSentry(error: any, customErrorMessage: string | null = null) {
    try {
      captureException(error, scope => {
        if (error && error.message) {
          scope.setTransactionName(error.message);
        }

        if (customErrorMessage) {
          scope.setTransactionName(customErrorMessage);
          const level: SeverityLevel = 'error';

          scope.addBreadcrumb({
            category: 'Custom Error Message',
            message: customErrorMessage,
            level: level
          });
        }
        return scope;
      });
    } catch (error) {
      console.log('error sending to Sentry', error);
    }
  }
}
