<div class="auto-ident__tac-iframe-wrapp">
  <div *ngIf="showErrorScreen" class="error-container">
    <div class="text-container">
      <img src="./assets/images/danger.png" alt="triangle danger" class="triangle-danger-icon" />
      <p class="error-message" [innerHTML]="'sonio_error_screen_text' | translate"></p>
    </div>
    <img src="./assets/images/dangerCircle.png" alt="circle danger" class="circle-danger-icon" />
    <button
      (click)="retryAutoIdentification()"
      class="retry-button"
      [innerHTML]="'retry_sonio_button' | translate"
    ></button>
  </div>
  <div class="loading" *ngIf="!showErrorScreen"></div>
  <iframe
    *ngIf="!showErrorScreen && isIframeLoaded"
    [src]="iframeSrcUrl | safeUrl"
    allow="camera; microphone"
    class="auto-ident__tac-iframe"
    sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts"
  ></iframe>
</div>
