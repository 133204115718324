<ng-container>
  <div class="header__wrapper" [ngStyle]="{ 'background-color': 'transparent' }">
    <div class="header-navigation">
      <div class="header-navigation__buttons">
        <div matRipple [matRippleColor]="matRippleColor" class="header-navigation__btn header-navigation__hide"
          (click)="navigateToHome()">
          <img [src]="homeImgSrc" alt="home" class="header-navigation__icon" />
        </div>
      </div>
    </div>
  </div>

  <div class="server-is-not-available-error">
    <img [src]="errorImgSrc" alt="error" />
    <p [innerHTML]="errorMsg"></p>
  </div>
</ng-container>