import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { RegistrationForm } from '@app/modules/registration/types/registration-form';

export const EMAILKEY = 'email';
export const CONFIRMEMAILKEY = 'confirmEmail';
export const EMAILTYPE = 'email';
export const PASSWORDKEY = 'password';
export const PASSWORDTYPE = 'password';
export const PHONENUMBERKEY = 'phone_number';
export const PHONENUMBERTYPE = 'phone-number';
export const DIALCODEKEY = 'dialCode';
export const SELECTTYPE = 'select';
export const NUMBERKEY = 'number';
export const TEXTTYPE = 'text';
export const GENDERKEY = 'gender';
export const FIRSTNAMEKEY = 'first_name';
export const LASTNAMEKEY = 'last_name';
export const BIRTHNAMEKEY = 'birth_name';
export const BIRTHDATEKEY = 'birth_date';
export const DATEPICKERTYPE = 'date-picker';
export const PLACEOFBIRTHKEY = 'place_of_birth';
export const COUNTRYOFBIRTHKEY = 'country_of_birth';
export const CITIZENSHIPKEY = 'citizenship';
export const OCCUPATIONKEY = 'occupation';
export const VERIFIEDPHONENUMBERKEY = 'verified_phone_number';
export const VERIFIEDPHONENUMBERTYPE = 'verified-phone-number';
export const SMSVERIFICATIONCODEKEY = 'sms_verification_code';
export const ADDRESSKEY = 'address';
export const ADDRESSTYPE = 'address';
export const STREETKEY = 'street';
export const HOUSENUMBERKEY = 'house_number';
export const CITYKEY = 'city';
export const ZIPCODEKEY = 'zip_code';
export const NEWSLETTERAGBKEY = 'newsletter_agb';
export const COUNTRYKEY = 'country';
export const AUTOIDENTKEY = 'auto_ident';
export const VERIFICATIONSTATUSKEY = 'verification_status';

export const registrationFormFields: RegistrationForm = {
  stepOne: [
    {
      key: EMAILKEY,
      label: __('email'),
      type: TEXTTYPE,
      required: true,
      isEmail: true
    },
    {
      key: CONFIRMEMAILKEY,
      label: __('confirm_email'),
      type: TEXTTYPE,
      required: false,
      hasLabelHidden: true
    },
    {
      key: PASSWORDKEY,
      label: __('password'),
      type: PASSWORDTYPE,
      required: true,
      validators: {
        minLength: 10,
        hasLowerCase: true,
        hasUpperCase: true,
        hasNumber: true,
        hasSpecialCharacter: true,
        maxLength: 22
      },
      registrationPassword: true
    },
    {
      key: PHONENUMBERKEY,
      label: __('mobile_number'),
      type: PHONENUMBERTYPE,
      required: true,
      options: [
        {
          key: 'de',
          value: 'GERMANY',
          code: '49'
        },
        {
          key: 'gb',
          value: 'UNITED KINGDOM',
          code: '44'
        }
      ],
      childFields: [
        {
          key: DIALCODEKEY,
          label: __('dial_code'),
          type: SELECTTYPE,
          required: true
        },
        {
          key: NUMBERKEY,
          label: __('mobile_number'),
          type: TEXTTYPE,
          required: true
        }
      ]
    }
  ],
  stepOtp: [
    {
      key: SMSVERIFICATIONCODEKEY,
      label: __('sms_verification_code'),
      type: TEXTTYPE,
      required: true,
      loginVerification: true,
      validators: {
        smsCodeInvalid: true
      }
    }
  ],
  stepTwo: [
    {
      key: 'limit',
      label: __('limit'),
      type: 'input-counter',
      required: true,
      default: 1000,
      disabled: true,
      validators: {
        minValue: 1,
        maxValue: 1000
      }
    }
  ],
  stepThree: [
    {
      key: GENDERKEY,
      label: __('gender'),
      type: SELECTTYPE,
      options: [
        {
          key: 'male',
          value: __('male'),
          withTranslation: true
        },
        {
          key: 'female',
          value: __('female'),
          withTranslation: true
        },
        {
          key: 'other',
          value: __('other'),
          withTranslation: true
        }
      ],
      required: true,
      default: 'male'
    },
    {
      key: FIRSTNAMEKEY,
      label: __('first_name'),
      type: TEXTTYPE,
      required: true,
      validators: {
        noNumber: true
      }
    },
    {
      key: LASTNAMEKEY,
      label: __('last_name'),
      type: TEXTTYPE,
      required: true,
      validators: {
        noNumber: true
      }
    },
    {
      key: BIRTHNAMEKEY,
      label: __('birth_name'),
      type: TEXTTYPE,
      required: false,
      validators: {
        noNumber: true
      }
    },
    {
      key: BIRTHDATEKEY,
      label: __('birth_date'),
      type: DATEPICKERTYPE,
      ageRestricted: true,
      required: true
    },
    {
      key: PLACEOFBIRTHKEY,
      label: __('place_of_birth'),
      type: TEXTTYPE,
      required: true,
      validators: {
        noNumber: true
      }
    },
    {
      key: COUNTRYOFBIRTHKEY,
      label: __('country_of_birth'),
      type: SELECTTYPE,
      options: [
        {
          key: 'DEU',
          value: 'Deutschland'
        },
        {
          key: 'AUT',
          value: 'Österreich'
        }
      ],
      required: true,
      default: 'DEU'
    },
    {
      key: CITIZENSHIPKEY,
      label: __('citizenship'),
      type: SELECTTYPE,
      options: [
        {
          key: 'DEU',
          value: 'Deutschland'
        },
        {
          key: 'AUT',
          value: 'Österreich'
        }
      ],
      required: true
    },
    {
      key: OCCUPATIONKEY,
      label: __('occupation'),
      type: SELECTTYPE,
      options: [
        {
          key: 'AN',
          value: 'Angestellter'
        }
      ],
      required: true,
      default: 'employee'
    },

    {
      key: ADDRESSKEY,
      label: __('address'),
      type: ADDRESSTYPE,
      required: true,
      childFields: [
        {
          key: STREETKEY,
          label: __('street'),
          type: TEXTTYPE,
          required: true
        },
        {
          key: HOUSENUMBERKEY,
          label: __('house_number'),
          type: TEXTTYPE,
          required: true
        },
        {
          key: CITYKEY,
          label: __('city'),
          type: TEXTTYPE,
          required: true
        },
        {
          key: ZIPCODEKEY,
          label: __('zip_code'),
          type: TEXTTYPE,
          required: true,
          validators: {
            zipCode: true
          }
        }
      ]
    }
  ]
};
