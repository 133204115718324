import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@app/modules/layout/layout.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@app/shared/interceptor/auth.interceptor';
import { TranslationModule } from '@app/shared/modules/translation.module';
import { TranslationInterceptor } from '@app/shared/interceptor/translation.interceptor';
import { SoftwareUpdateComponent } from './modules/software-update/software-update.component';
import { CheckServerStatusInterceptor } from './shared/interceptor/check-server-status.interceptor';
import { InternetConnectionStatusComponent } from './shared/components/internet-connection-status/internet-connection-status.component';
import { ServerIsNotAvailableErrorPageModule } from './modules/server-is-not-available-error-page/server-is-not-available-error-page.module';
import { MaintenanceModePageComponent } from './modules/maintenance-mode-page/maintenance-mode-page.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    SoftwareUpdateComponent,
    InternetConnectionStatusComponent,
    MaintenanceModePageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslationModule,
    LayoutModule,
    ServerIsNotAvailableErrorPageModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TranslationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CheckServerStatusInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
