<div class="popup">
  <button mat-icon-button class="popup__close-btn" mat-dialog-close>
    <mat-icon class="popup__close-btn--icon">close</mat-icon>
  </button>

  <div
    mat-dialog-content
    [ngClass]="data.cssClass"
    [innerHTML]="
      data.withTranslation
        ? (data.content | translate) +
          '<br></br>' +
          (data?.additionalContent
            ? (data?.additionalContent | translate) +
              ' ' +
              (data?.additionalContentValue ? data?.additionalContentValue : '')
            : '')
        : data.content | safeHtml
    "
  ></div>
  <div *ngIf="data.buttons" mat-dialog-actions class="popup__actions">
    <button
      *ngIf="data.buttons.action"
      class="popup__actions--button btn-yellow"
      mat-button
      mat-raised-button
      (click)="navigateToHome()"
      [innerHTML]="data.buttons.action | translate"
    ></button>
    <button
      *ngIf="data.buttons.close"
      class="popup__actions--button btn-yellow-outline"
      mat-button
      mat-raised-button
      mat-dialog-close
      [innerHTML]="data.buttons.close | translate"
    ></button>
  </div>
</div>
