import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular-ivy';
// import { AppConfig } from '@app/shared/models/app-config';

(async () => {
  /*

  // TODO: Figure out why app.config.json is fetched.
  // Loading the config from app.config.json overwrites values from environment.ts and breaks production builds.

  const response = await fetch('assets/config/app.config.json');
  const config: AppConfig = await response.json();

  environment['hostUrl'] = config.hostUrl;
  environment['production'] = config.production;
  environment['project'] = config.project;
  environment['idNowApiUrl'] = config.idNowApiUrl;
  environment['screenTimeout'] = config.screenTimeout;

  */

  if (environment.production) {
    const SENTRY_ENV =
      window.location.href.includes('localhost') || window.location.href.includes('test')
        ? 'test'
        : window.location.href.includes('demo')
        ? 'demo'
        : window.location.href.includes('staging')
        ? 'staging'
        : 'production';
    Sentry.init({
      dsn: 'https://476d5624a80090f318dffa58c92ff1fc@o4506025312321536.ingest.sentry.io/4506026073456640',
      environment: SENTRY_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'https://test.wanda.mest.global-soft.dev/api/v1',
            'https://admin-mest-demo.staging.multilog24.de/api/v1',
            'https://admin-mest.staging.multilog24.de/api/v1',
            'https://admin-mest.multilog24.de/api/v1'
          ],
          routingInstrumentation: Sentry.routingInstrumentation
        })
      ],
      release: '1.3.0-sourceMaps',
      enabled:
        window.location.hostname === 'localhost' || window.location.hostname === 'test.vision.mest.global-soft.dev'
          ? false
          : true,
      // Performance Monitoring
      tracesSampleRate: 1.0 // Capture 100% of the transactions, reduce in production!
    });
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
})();
