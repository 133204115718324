<form class="vs-input-counter" [formGroup]="registrationForm">
    <a matRipple
       matRippleColor="rgb(20,43,81,30%)"
       class="vs-input-counter__button minus"
       (click)="onAction(formControl.value, 'decrease')">-</a>
    <div class="vs-input-counter__input ">
        <span class="vs-input-counter__input--currency-symbol">
            <input class="vs-input-counter__input--text"
                   disabled="this.config.disabled"
                   [formControlName]="config.key"
                   (input)="onAction($event, 'changeInput')"
                   autocomplete="off">
        </span>
    </div>
    <a matRipple
       matRippleColor="rgb(20,43,81,30%)"
       class="vs-input-counter__button plus"
       (click)="onAction(formControl.value, 'increase')">+</a>
</form>
