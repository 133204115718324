import { Component, OnInit } from '@angular/core';
import { Theme } from '@themes/models/theme';
import { ThemeService } from '@app/modules/layout/services/theme.service';
import { MatDialog } from '@angular/material/dialog';
// eslint-disable-next-line max-len
import { ThemeSwitcherDialogComponent } from '@app/modules/layout/components/theme-switcher-dialog/theme-switcher-dialog.component';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss']
})
export class ThemeSwitcherComponent implements OnInit {
  private themeSwitcherDialogConfig = {
    data: {
      cssClass: 'popup__language-switcher',
      withTranslation: false
    },
    width: '60%',
    height: '50%',
    panelClass: 'popup',
    autoFocus: false
  };

  public theme: Theme;
  constructor(private themeService: ThemeService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.themeService.getTheme().subscribe(theme => (this.theme = theme));
  }

  showThemeSwitcherDialog(): void {
    this.dialog.open(ThemeSwitcherDialogComponent, this.themeSwitcherDialogConfig);
  }
}
