import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-vs-prefix-name-field',
  templateUrl: './vs-prefix-name-field.component.html',
  styleUrls: ['./vs-prefix-name-field.component.scss']
})
export class VsPrefixNameFieldComponent {
  @HostBinding('class') hostClass = 'vs-prefix-name-field__form';
  @Input() config: any;
  @Input() registrationForm: FormGroup;
}
