import { Component, Input } from '@angular/core';
import { FormInput } from '@app/shared/models/form-input';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-vs-checkbox',
  templateUrl: './vs-checkbox.component.html',
  styleUrls: ['./vs-checkbox.component.scss']
})
export class VsCheckboxComponent {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;
}
