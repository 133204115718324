import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormInput } from '@app/shared/models/form-input';
import { HelperService } from '@app/shared/services/helper.service';

@Component({
  selector: 'app-vs-input-counter',
  templateUrl: './vs-input-counter.component.html',
  styleUrls: ['./vs-input-counter.component.scss']
})
export class VsInputCounterComponent implements OnInit {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;

  formControl = new FormControl('');

  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.formControl.setValue(this.config.default);

    const validators = [];
    validators.push(Validators.max(this.config.validators.maxValue));
    validators.push(Validators.min(this.config.validators.minValue));
    validators.push(Validators.required);

    this.registrationForm.addControl(this.config.key, this.formControl);
  }

  onAction(event: any, actionType: string): void {
    let newValue;
    switch (actionType) {
      case 'increase':
        newValue = event + 1;
        break;
      case 'decrease':
        newValue = event - 1;
        break;
      case 'changeInput':
        newValue = +event.target.value;
        break;
      default:
        newValue = this.config.default;
    }

    this.helperService.updateDepositLimitValue(newValue, this.registrationForm.get('limit'), this.config);
  }
}
