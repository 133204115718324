import { KeyboardLayoutObject } from 'simple-keyboard/build/types/interfaces';

export default <KeyboardLayoutObject>{
  default: [
    'q w e r t y u i o p \u00E9 \u00EB \u00FF',
    'a s d f g h j k l \u00F3 \u00EF \u0133',
    '{shift} z x c v b n m {backspace}',
    '{alt} {nl} @ {space} . {enter}'
  ],
  alt: [
    '1 2 3 4 5 6 7 8 9 0',
    `_ / ! # $ & + [ ] < > $ \u00B4 ' "`,
    '* % ^ - + = ; : , ? ( ) {backspace}',
    '{ABC} {nl} @ {space} . {enter}'
  ],
  num: ['1 2 3', '4 5 6', '7 8 9', '0 {backspace}'],
  shift: [
    'Q W E R T Y U I O P \u00C9 \u00CB \u0178',
    'A S D F G H J K L \u00D3 \u00CF \u0132',
    '{shift} Z X C V B N M {backspace}',
    '{alt} {nl} @ {space} . {enter}'
  ]
};
