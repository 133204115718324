import { Component, Input, OnInit } from '@angular/core';
import { FormInput } from '@app/shared/models/form-input';
import { FormGroup } from '@angular/forms';
import { HelperService } from '@app/shared/services/helper.service';
import { MatSliderChange } from '@angular/material/slider/slider';

@Component({
  selector: 'app-vs-slider',
  templateUrl: './vs-slider.component.html',
  styleUrls: ['./vs-slider.component.scss']
})
export class VsSliderComponent implements OnInit {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;
  maxValue: number;
  minValue: number;

  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.maxValue = this.config.validators.maxValue;
    this.minValue = this.config.validators.minValue;
  }

  onSlide(event: MatSliderChange): void {
    this.helperService.updateDepositLimitValue(event.value, this.registrationForm.get(this.config.key), this.config);
  }
}
