import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-vs-button',
  templateUrl: './vs-button.component.html',
  styleUrls: ['./vs-button.component.scss']
})
export class VsButtonComponent implements OnInit {
  @Output() btnClick = new EventEmitter();
  @Input() isDisabled: boolean = false;
  @Input() text: string = '';
  @Input() hasCountdownBreak: boolean = false;
  @Input() countdownTimeInS: number | null = null;

  public timerStarted = false;
  public secondsRemaining$: Observable<number> = timer(0, 1000).pipe(
    map(n => {
      if (this.countdownTimeInS - n <= 0) this.timerStarted = false;
      return this.countdownTimeInS - n;
    }),
    takeWhile(n => n >= 0)
  );

  ngOnInit(): void {}

  onClick() {
    if (this.hasCountdownBreak) {
      this.startCountDown();
    }

    this.btnClick.emit();
  }

  startCountDown() {
    if (this.timerStarted && !this.countdownTimeInS) return true;

    this.timerStarted = true;
  }

  ngOnDestroy() {}
}
