<div class="banknote-box__value">
    {{ billConfig.type }} {{currencySymbol}}
</div>
<div class="banknote-box">
    <div [ngClass]="!billConfig.canIncrease ? 'banknote-box__value--disabled' : ''"
         class="banknote-box__minus-plus-btn"
         (click)="updateAmount('increase')">+
    </div>
    <div class="banknote-box__bills-nb"> {{counter}}</div>
    <div [ngClass]="!canDecrease ? 'banknote-box__value--disabled' : ''" class="banknote-box__minus-plus-btn"
         (click)="updateAmount('decrease')">-
    </div>
</div>

