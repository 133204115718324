import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(): boolean {
    if (!this.authService.isLoggedIn()) {
      // try to do re-login
      this.authService.login();

      return false;
    }
    return true;
  }
}
