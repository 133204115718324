<div class="popup">
  <button *ngIf="data && data.closeBtn" mat-icon-button class="popup__close-btn" mat-dialog-close>
    <mat-icon class="popup__close-btn--icon">close</mat-icon>
  </button>
  <div mat-dialog-content *ngIf="data && data.content" [ngClass]="data.cssClass" class="popup__inner-container">
    <div class="info-text">
      <div
        [innerHTML]="data.withTranslation ? (data.content | translate) : data.content"
        class="info-text-container"
      ></div>
      <div *ngIf="data && data.buttons" mat-dialog-actions class="popup__images-actions">
        <button
          *ngIf="data.buttons.actionCancel"
          class="popup__images-actions--button btn-yellow-outline"
          mat-button
          mat-raised-button
          (click)="cancel()"
          [innerHTML]="data.buttons.actionCancel | translate"
        ></button>
        <button
          *ngIf="data.buttons.actionConfirm"
          class="popup__images-actions--button btn-yellow"
          mat-button
          mat-raised-button
          (click)="confirm()"
          [innerHTML]="data.buttons.actionConfirm | translate"
        ></button>
      </div>
    </div>
    <ng-container *ngIf="data?.imgSrc">
      <div class="info-images">
        <img [src]="data.imgSrc" />
        <img [src]="data.imgSrc2" />
      </div>
    </ng-container>
  </div>
</div>
