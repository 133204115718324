import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { TranslationService } from '@app/shared/services/translation.service';

@Component({
  selector: 'app-agb-popup',
  templateUrl: './agb-popup.component.html',
  styleUrls: ['./agb-popup.component.scss']
})
export class AgbPopupComponent implements OnInit {
  iframeSrcUrl: string;
  environment: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { cssClass?: string; content?: string },
    public config: ProjectConfigService,
    private translationService: TranslationService
  ) {
    this.environment = this.config.getConfig();
  }

  ngOnInit(): void {
    const currentLanguage = this.translationService.getCurrentLanguage();
    const pageUrl = this.data.content === 'agb' ? '/terms/' : '/privacy/';
    this.iframeSrcUrl = this.environment.idNowApiUrl + pageUrl + currentLanguage;
  }
}
