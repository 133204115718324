import { ChangeDetectorRef, Component, ElementRef, TemplateRef } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { HeaderService } from '@app/shared/services/header.service';
import { Directionality } from '@angular/cdk/bidi';
import { CdkStep } from '@angular/cdk/stepper/stepper';
import { StepperSettings } from '@app/shared/models/stepper-settings';

@Component({
  selector: 'app-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrls: ['./form-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: FormStepperComponent }]
})
export class FormStepperComponent extends CdkStepper {
  constructor(
    private headerService: HeaderService,
    public dir: Directionality,
    public changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef<HTMLElement>
  ) {
    super(dir, changeDetectorRef, elementRef, document);
  }

  getStepContent(selectedStep: CdkStep | undefined): TemplateRef<any> | null {
    if (selectedStep) {
      this.headerService.setStepperSettings({
        index: this.selectedIndex,
        label: this.selected.label,
        steps: this.steps
      } as StepperSettings);
      return selectedStep.content;
    }

    return null;
  }
}
