import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationModalComponent } from '@app/shared/components/notifications/notification-modal.component';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  showMessage(message: string, type = 'success', hasDismissAction = false, duration?: number): MatSnackBarRef<any> {
    return this.snackBar.openFromComponent(NotificationModalComponent, {
      panelClass: ['notification-message__container', `notification-message__container--${type}`],
      verticalPosition: 'top',
      horizontalPosition: 'center',
      data: {
        dismiss: hasDismissAction ? __('close') : null,
        message
      },
      duration
    });
  }
}
