<div class="account-info__bg"></div>
<div class="dashboard__wrapper">
  <div class="account-info__wrapper">
    <div class="account-info__content left">
      <div>
        <h1 [innerHTML]="withCasinos ? ('acc_info_temp_account_ready' | translate) : ('acc_info_temp_account_ready_alt' | translate)">
        </h1>
        <p [innerHTML]="withCasinos ? ('acc_info_temp_account_message' | translate) : ('acc_info_temp_account_message_alt' | translate)">
        </p>
        <ul>
          <li>
            <img src="assets/icons/ok.png" />
            <span class="account-info__content--text" [innerHTML]="withCasinos ? ('acc_info_max_deposit' | translate) : ('acc_info_max_deposit_alt' | translate)"></span>
          </li>
          <li>
            <img src="assets/icons/not_ok.png" />

            <span class="account-info__content--text" [innerHTML]="withCasinos ? ('acc_info_payout' | translate) : ('acc_info_payout_alt' | translate)"></span>
          </li>
          <li>
            <img src="assets/icons/not_ok.png" />
            <span class="account-info__content--text" [innerHTML]="withCasinos ? ('acc_info_bonus' | translate) : ('acc_info_bonus_alt' | translate)">
            </span>
          </li>
          <li>
            <img src="assets/icons/not_ok.png" />

            <span class="account-info__content--text" [innerHTML]="withCasinos ? ('acc_info_free_spins' | translate) : ('acc_info_free_spins_alt' | translate)"></span>
          </li>
        </ul>
      </div>
      <a
        matRipple
        matRippleColor="rgb(20,43,81,30%)"
        class="account-info__button account-info__button--cta"
        (click)="navigateToProfile()"
        [innerHTML]="withCasinos ? ('acc_info_use_temp_account' | translate) : ('acc_info_use_temp_account_alt' | translate)"
      >
      </a>
    </div>
    <div class="account-info__content right">
      <div>
        <h1 [innerHTML]="withCasinos ? ('acc_info_use_complete_info' | translate) : ('acc_info_use_complete_info_alt' | translate)">
        </h1>
        <p [innerHTML]="withCasinos ? ('acc_info_one_more_step_message' | translate) : ('acc_info_one_more_step_message_alt' | translate)">
        </p>
        <ul>
          <li>
            <img src="assets/icons/ok.png" />

            <span class="account-info__content--text" [innerHTML]="withCasinos ? ('acc_info_increase_limit' | translate) : ('acc_info_increase_limit_alt' | translate)"></span>
          </li>
          <li>
            <img src="assets/icons/ok.png" />

            <span class="account-info__content--text" [innerHTML]="withCasinos ? ('acc_info_immediate_pay' | translate) : ('acc_info_immediate_pay_alt' | translate)"></span>
          </li>
          <li>
            <img src="assets/icons/ok.png" />

            <span class="account-info__content--text" [innerHTML]="withCasinos ? ('acc_info_deposit_bonus' | translate) : ('acc_info_deposit_bonus_alt' | translate)"></span>
          </li>
          <li>
            <img src="assets/icons/ok.png" />
            <span class="account-info__content--text" [innerHTML]="withCasinos ? ('acc_info_free_spins' | translate) : ('acc_info_free_spins_alt' | translate)"></span>
          </li>
        </ul>
      </div>
      <a
        matRipple
        matRippleColor="rgb(20,43,81,30%)"
        class="account-info__button"
        queryParamsHandling="merge"
        routerLink="/app/customer/video-ident"
        [innerHTML]="withCasinos ? ('acc_info_use_complete_info' | translate) : ('acc_info_use_complete_info_alt' | translate)"
      >
      </a>
    </div>
  </div>
</div>
