import { KeyboardLayoutObject } from 'simple-keyboard/build/types/interfaces';

export default <KeyboardLayoutObject>{
  default: [
    'q w e r t z u i o p \u00FC \u00DF',
    'a s d f g h j k l \u00F6 \u00E4',
    '{shift} y x c v b n m {backspace}',
    '{alt} {de} @ {space} . {enter}'
  ],
  alt: [
    '1 2 3 4 5 6 7 8 9 0',
    `_ / ! # $ & + [ ] < > $ \u00B4 ' "`,
    '* % ^ - + = ; : , ? ( ) {backspace}',
    '{ABC} {de} @ {space} . {enter}'
  ],

  num: ['1 2 3', '4 5 6', '7 8 9', '0 {backspace}'],
  shift: [
    'Q W E R T Z U I O P \u00DC \u1E9E',
    'A S D F G H J K L \u00D6 \u00C4',
    '{shift} Y X C V B N M {backspace}',
    '{alt} {de} @ {space} . {enter}'
  ]
};
