import { Injectable, RendererFactory2, ViewEncapsulation, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LinkDefinition } from '@app/shared/models/link-definition';
import { TranslateService } from '@ngx-translate/core';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root'
})
export class PreloadService {
  initialTranslations;
  tsTagsToPreload = [
    __('paylado_payin'),
    __('my_account'),
    __('registration'),
    __('choose_lang_btn_label'),
    __('age_restriction'),
    __('footer_text_message'),
    __('server_is_not_available_error_msg'),
    __('terminal_not_registered'),
    __('terminal_not_active_text'),
    __('terminal_not_registered_text'),
    __('error_something_went_wrong')
  ];

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService
  ) {}

  preloadResources(): void {
    const resourcesPath = [
      '/assets/flags-svg/en.svg',
      '/assets/flags-svg/de.svg',
      '/assets/icons/18+.svg',
      '/assets/icons/home.png',
      '/assets/icons/home.svg',
      'assets/icons/info.svg',
      '/assets/icons/info.png',
      '/assets/logo/merkur-home-logo.png',
      '/assets/logo/tipwin-home-logo.png',
      '/assets/logo/merkur-logo.png',
      '/assets/logo/tipwin-logo.png',
      '/assets/icons/not_ok.png'
    ];

    for (const item of resourcesPath) {
      this.addTag({ rel: 'preload', as: 'image', href: item });
    }
  }

  /**
   * Inject the State into the bottom of the <head>
   */
  addTag(tag: LinkDefinition): void {
    try {
      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });

      const link = renderer.createElement('link');
      const head = this.document.head;

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }

      Object.keys(tag).forEach((prop: string) => {
        return renderer.setAttribute(link, prop, tag[prop]);
      });

      renderer.appendChild(head, link);
    } catch (e) {
      console.error('Error within preloadService : ', e);
    }
  }

  cacheInitialTranslations(): void {
    this.translate.get(this.tsTagsToPreload).subscribe(response => {
      localStorage.setItem('langCache', JSON.stringify({ de: response }));
    });
  }

  getTranslationsFromCache(currentLang: string): string | null {
    const translation = JSON.parse(localStorage.getItem('langCache'));
    return translation ? translation[currentLang] : null;
  }

  getTranslationsFromRequest(): { [key: string]: string } {
    this.translate.get(this.tsTagsToPreload).subscribe(response => {
      this.initialTranslations = response;
    });

    return this.initialTranslations;
  }
}
