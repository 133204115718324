import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Keyboard from 'simple-keyboard';
import { FormGroup } from '@angular/forms';
import { HelperService } from '@app/shared/services/helper.service';
import { FormInput } from '@app/shared/models/form-input';
import { TranslationService } from '@app/shared/services/translation.service';
import ENGLISH from './language-layouts/english';
import GERMAN from './language-layouts/german';
import TURKISH from './language-layouts/turkish';
import SPANISH from './language-layouts/spanish';
import DUTCH from './language-layouts/dutch';
import { KeyboardLayoutObject } from 'simple-keyboard/build/types/interfaces';
import {
  ADDRESSKEY,
  EMAILKEY,
  NUMBERKEY,
  PASSWORDKEY,
  PHONENUMBERKEY,
  SMSVERIFICATIONCODEKEY,
  VERIFIEDPHONENUMBERKEY
} from '@app/modules/registration/registration.model';

// todo: Find a better way to get the nativeElement by FormControlName
/* const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function() {
    const result = originFormControlNameNgOnChanges.apply(this, ...arguments);
    this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
    return result;
};*/

export const languageKeyboardLayout = {
  en: ENGLISH,
  de: GERMAN,
  tr: TURKISH,
  es: SPANISH,
  nl: DUTCH
};

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnInit {
  @Input() registrationForm: FormGroup;
  @Input() stepper: any;
  @Output() public enterKeyPressed: EventEmitter<void> = new EventEmitter();

  keyboard: Keyboard;
  fieldConfig: FormInput;
  shiftActive = false;
  showKeyboard = true;
  altActive = false;
  numActive = false;
  loginVerification = false;

  private processingChange = false;

  constructor(private keyboardService: HelperService, private translationService: TranslationService) {}

  ngOnInit(): void {
    this.keyboardService.inputKeyboardConfig.subscribe(data => {
      this.fieldConfig = data;
      this.keyboard.setOptions({
        inputName: this.fieldConfig.key
      });

      if (this.fieldConfig.key == PHONENUMBERKEY) {
        this.keyboard.setOptions({
          layoutName: 'alt'
        });
        this.numActive = false;
        this.altActive = true;
        this.loginVerification = false;
      } else if (this.fieldConfig.key == SMSVERIFICATIONCODEKEY) {
        this.keyboard.setOptions({
          layoutName: 'num'
        });
        this.numActive = true;
        this.altActive = false;
        this.loginVerification = false;
        this.fieldConfig.loginVerification ? (this.loginVerification = true) : (this.loginVerification = false);
      } else {
        this.keyboard.setOptions({
          layoutName: 'default'
        });
        this.numActive = false;
        this.altActive = false;
        this.loginVerification = false;
      }

      if (
        this.registrationFormStepOne &&
        this.registrationFormStepOne.get(this.fieldConfig.key) &&
        !['agb', PHONENUMBERKEY].includes(this.fieldConfig.key)
      ) {
        this.keyboard.setInput(this.registrationFormStepOne.get(this.fieldConfig.key).value);
      } else if (
        this.registrationFormStepOne &&
        this.registrationFormStepOne.get(this.fieldConfig.key) &&
        [PHONENUMBERKEY].includes(this.fieldConfig.key)
      ) {
        this.keyboard.setInput(this.registrationFormPhoneNumber.get(NUMBERKEY).value);
      } else if (
        this.registrationFormStepThree &&
        this.registrationFormStepThree.get(this.fieldConfig.key) &&
        this.fieldConfig.key !== ADDRESSKEY
      ) {
        this.keyboard.setInput(this.registrationFormStepThree.get(this.fieldConfig.key).value);
      }
    });

    // reset keyboard input once x button is clicked or reset keyboard by inputName
    this.keyboardService.clearInput.subscribe(data => {
      if (data.clearInput) {
        if (data.inputName) {
          this.keyboard.clearInput(data.inputName);
        } else {
          this.keyboard.clearInput();
        }
      }
    });

    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      preventMouseDownDefault: true,
      layout: this.getCurrentLangLayout,
      layoutName: 'default',
      display: {
        '{alt}': '123',
        '{backspace}': '⌫',
        '{shift}': '⇧',
        '{space}': ' ',
        '{enter}': '  ↵  ',
        '{de}': 'DE',
        '{en}': 'EN',
        '{tr}': 'TR',
        '{es}': 'ES',
        '{nl}': 'NL',
        '{ABC}': 'ABC'
      }
    });

    this.keyboardService.depositLimitValue.subscribe(data => {
      this.keyboard.setInput(String(data));
    });

    this.registrationFormStepOne?.get(PASSWORDKEY)?.valueChanges.subscribe(data => {
      this.keyboard.setInput(data);
    });

    this.keyboardService.showVirtualKeyboard.subscribe(value => {
      this.showKeyboard = value;
    });
  }

  get registrationFormStepOne(): FormGroup {
    return this.registrationForm?.get('stepOne') as FormGroup;
  }

  get registrationFormStepTwo(): FormGroup {
    return this.registrationForm?.get('stepTwo') as FormGroup;
  }

  get registrationFormStepThree(): FormGroup {
    return this.registrationForm?.get('stepThree') as FormGroup;
  }

  get registrationFormAddress(): FormGroup {
    return this.registrationFormStepThree?.get(ADDRESSKEY) as FormGroup;
  }

  get registrationFormPhoneVerification(): FormGroup {
    return this.registrationFormStepThree?.get(VERIFIEDPHONENUMBERKEY) as FormGroup;
  }

  get registrationFormPhoneNumber(): FormGroup {
    return this.registrationFormStepOne?.get(PHONENUMBERKEY) as FormGroup;
  }

  onChange(input: string): void {
    if (this.processingChange) {
      return;
    }
    this.processingChange = true;
    const currentInputName = this.keyboard.getOptions().inputName;
    if (
      this.registrationFormStepOne &&
      this.registrationFormStepOne.get(currentInputName) &&
      !['agb', PHONENUMBERKEY].includes(currentInputName)
    ) {
      this.registrationForm.controls.stepOne.get(currentInputName).setValue(input);
    }

    if (currentInputName === PHONENUMBERKEY) {
      this.registrationFormPhoneNumber.get(NUMBERKEY).setValue(input);
    }

    // check if current input is address field and set its value
    if (currentInputName === ADDRESSKEY) {
      this.registrationFormAddress.get('tmpSearch').setValue(input);
    }

    // check if current input belongs to second step and set its value
    if (
      this.registrationFormStepThree &&
      this.registrationFormStepThree.get(currentInputName) &&
      currentInputName !== ADDRESSKEY &&
      currentInputName != SMSVERIFICATIONCODEKEY
    ) {
      this.registrationFormStepThree.get(currentInputName).setValue(input);
    }

    // check if current input belongs to address sub fields and set its value
    if (this.registrationFormAddress && this.registrationFormAddress.get(currentInputName)) {
      this.registrationFormAddress.get(currentInputName).setValue(input);
    }

    // check if current input belongs to phone verification sub fields and set its value
    if (this.registrationFormPhoneVerification && this.registrationFormPhoneVerification?.get(currentInputName)) {
      if (currentInputName === PHONENUMBERKEY) {
        this.registrationFormPhoneVerification.get(this.fieldConfig.key).get(NUMBERKEY).setValue(input);
        return;
      }
    }

    setTimeout(() => {
      const position = this.getCaretPosition();
      let inputElement;
      if (currentInputName === ADDRESSKEY) {
        inputElement = document.getElementById('mat-input-0');
      } else {
        inputElement = document.getElementById(currentInputName) as HTMLInputElement;
      }
      if (inputElement && position !== null) {
        inputElement.focus();
        inputElement.setSelectionRange(position, position);
      }
    }, 0);
    this.processingChange = false;
  }

  getCaretPosition(): number {
    return this.keyboard.getCaretPosition();
  }

  setCaretPosition(newPosition: number): void {
    this.keyboard.setCaretPosition(newPosition);
  }

  onKeyPress(button: string): void {
    if (this.fieldConfig.key == SMSVERIFICATIONCODEKEY) this.keyboardService.setKeyPressedValue(button);
    if (button === '{shift}' || this.shiftActive) {
      this.handleShift();
    }

    if (button === '{en}' || button === '{de}' || button === '{tr}' || button === '{es}' || button === '{nl}') {
      this.handleSwitchLayout(button);
    }

    if (button === '{alt}' || button === '{ABC}') {
      this.handleAlt();
    }

    if (button === '{enter}') {
      this.enterKeyPressed.emit();
    }

    if (button === '{space}' && ['agb'].includes(this.keyboard.getOptions().inputName)) {
      // set agb checkbox with space button
      const currentValue = this.registrationFormStepOne.get(this.keyboard.getOptions().inputName).value ?? false;
      this.registrationFormStepOne.get(this.keyboard.getOptions().inputName).setValue(!currentValue);
    }
  }

  handleLock(): void {
    const currentLayout = this.keyboard.options.layoutName;
    this.keyboard.setOptions({
      layoutName: currentLayout === 'default' ? 'shift' : 'default'
    });
  }

  handleShift(): void {
    this.shiftActive = !this.shiftActive;

    this.keyboard.setOptions({
      display: {
        ...this.keyboard.options.display,
        '{shift}': this.shiftActive ? '⬆' : '⇧'
      },
      layoutName: this.shiftActive ? 'shift' : 'default'
    });
    const shiftButton = document.querySelector('.simple-keyboard .hg-button[data-skbtn="{shift}"]');
    if (shiftButton && this.shiftActive) {
      shiftButton.classList.add('shift-active');
    } else {
      shiftButton.classList.remove('shift-active');
    }
  }

  handleSwitchLayout(button: string): void {
    let langLayout: KeyboardLayoutObject = languageKeyboardLayout.de;
    switch (button) {
      case '{de}':
        langLayout = languageKeyboardLayout.en;
        break;
      case '{en}':
        langLayout = languageKeyboardLayout.tr;
        break;
      case '{tr}':
        langLayout = languageKeyboardLayout.es;
        break;
      case '{es}':
        langLayout = languageKeyboardLayout.nl;
        break;
      case '{nl}':
        langLayout = languageKeyboardLayout.de;
        break;
      default:
        langLayout = languageKeyboardLayout.de;
        break;
    }

    this.keyboard.setOptions({
      layout: langLayout
    });
  }

  get getCurrentLangLayout(): KeyboardLayoutObject {
    const currentLang = this.translationService.getCurrentLanguage();
    const defaultLangLayout: KeyboardLayoutObject = languageKeyboardLayout.de;

    if (currentLang && languageKeyboardLayout[currentLang]) {
      return languageKeyboardLayout[currentLang];
    }
    return defaultLangLayout;
  }

  handleAlt(): void {
    this.keyboard.setOptions({
      layoutName: this.altActive && this.shiftActive ? 'shift' : this.altActive ? 'default' : 'alt'
    });
    this.altActive = !this.altActive;
  }
}
