import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectConfigService {
  projectConfig: { [key: string]: unknown };

  initConfig(config: { [key: string]: unknown }): void {
    this.projectConfig = config;
  }

  getConfig(key = null): any {
    return key ? this.projectConfig[key] : this.projectConfig;
  }
}
