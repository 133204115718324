import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { environment } from '@env/environment';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  missingTranslationKeys = [];

  handle(params: MissingTranslationHandlerParams): string {
    const translationKey = params.key;
    const missingTranslationMessage = `Missing translation: Please add "${translationKey}" in to static translation keys. (@translations/static-translation-keys)`;
    if (!this.isMissingTranslation(translationKey)) {
      if (!environment.production) {
        console.error(missingTranslationMessage);
      } else {
        console.warn(missingTranslationMessage);
      }

      this.missingTranslationKeys.push(translationKey);
    }

    return translationKey;
  }

  isMissingTranslation(translationKey: string): boolean {
    return translationKey in this.missingTranslationKeys;
  }
}
