import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { DataFetchService } from '@app/shared/services/data-fetch.service';
import { LoqateFindItem } from '@app/modules/registration/models/loqate-find-item';
import { LoqateAddress } from '@app/modules/registration/models/loqate-address';
import { LoqateGetItem } from '@app/modules/registration/models/loqate-get-item';

@Injectable({
  providedIn: 'root'
})
export class LoqateService extends DataFetchService {
  addressDetails = new BehaviorSubject<LoqateAddress>({
    city: '',
    country: '',
    house_number: '',
    street: '',
    zip_code: ''
  });

  loqateAddress$ = this.addressDetails.asObservable();

  constructor(public http: HttpClient, public config: ProjectConfigService) {
    super(http, config);
  }

  findLoqateRequest(payload = {}): Observable<any> {
    payload = {
      text: '',
      containerId: '',
      languageId: 1,
      limit: 50,
      ...payload
    };

    return this.post<LoqateFindItem[]>(`${this.environment.hostUrl}/${this.environment.loqateFind}`, { ...payload });
  }

  getLoqateRequest(payload = {}): void {
    payload = {
      id: '',
      ...payload
    };

    this.post<LoqateGetItem[]>(`${this.environment.hostUrl}/${this.environment.loqateRetrieve}`, {
      ...payload
    }).subscribe(address => {
      const details = address[0];
      this.addressDetails.next({
        street: details.street,
        house_number: details.houseNumber,
        city: details.city,
        zip_code: details.zip,
        country: details.country
      });
    });
  }
}
