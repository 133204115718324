<div class="popup">
    <button mat-icon-button class="popup__close-btn"  mat-dialog-close>
        <mat-icon class="popup__close-btn--icon">close</mat-icon>
    </button>

    <div mat-dialog-content [ngClass]="data.cssClass">
        <label class="popup__language-switcher--text" [innerHTML]="'choose_lang_popup_text' | translate"></label>
        <div class="popup__language-switcher--flags">
            <div *ngFor="let language of languages" style="display: flex; align-items: center ;flex-direction: column">
                <div [ngClass]="['lang__flag lang__flag--'  + language.iso]" (click)="setLanguage(language.iso)"></div>
                <span [innerHTML]="language.name"></span>
            </div>
        </div>
    </div>
</div>
