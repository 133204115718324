import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  /**
   * It will allow URls to be rendered in HTML, for example for an Iframe
   *
   * @params {value: any} url to be trusted
   */
  transform(value: string): SafeResourceUrl {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}
