import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { ActivatedRoute } from '@angular/router';
import { PageReloadService } from '@app/shared/services/page-reload.service';
import { AuthService } from '@app/shared/services/auth.service';
import { HeaderService } from '@app/shared/services/header.service';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { Router } from '@angular/router';
import { HeaderSettings } from '@app/shared/models/header-settings';
import { PrinterService } from '@app/modules/customer/services/printer.service';
import { Subscription } from 'rxjs';
import { DataShareService } from '@app/shared/services/data-share.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent extends BaseComponent implements OnInit, OnDestroy {
  printoutEnabled = false;
  printerSub: Subscription;
  casinosSub: Subscription;
  withCasinos = false;

  headerSettings: HeaderSettings = {
    showHeader: true,
    showStepper: false
  };

  constructor(
    private router: Router,
    private dataShareService: DataShareService,
    public activatedRoute: ActivatedRoute,
    public pageReload: PageReloadService,
    public authService: AuthService,
    public headerService: HeaderService,
    public printerService: PrinterService
  ) {
    super(activatedRoute, pageReload, authService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // Hide header's stepper
    this.headerService.setHeaderSettings(this.headerSettings);
    this.headerService.setHelpText(__('help_text_account_info'));

    // check if printout is enabled/disabled
    this.printerService.enablePrinter.subscribe(response => {
      this.printoutEnabled = response;
      if (this.printoutEnabled) {
        this.printerService.preparePrintingData();
      }
    });

    this.allSubscriptions.push(
      this.dataShareService.casinos.subscribe(casinos => {
        this.withCasinos = casinos.length > 0;
      })
    );
  }

  navigateToProfile(): void {
    this.router.navigate(['/app/customer/profile'], { queryParamsHandling: 'merge' });
  }
}
