import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vs-continue-button',
  templateUrl: './vs-continue-button.component.html',
  styleUrls: ['./vs-continue-button.component.scss']
})
export class VsContinueButtonComponent implements OnInit, OnChanges {
  @Output() public clickEvent: EventEmitter<void> = new EventEmitter();
  @Input() textButton = __('continue');
  @Input() isButtonInactive: boolean;
  @Input() isSubmitting: boolean;
  @Input() isDisabled: boolean;
  public buttonInactive: boolean = false;
  public buttonDisabled: boolean = false;
  public buttonSubmitting: boolean = false;
  ngOnInit(): void {
    this.buttonInactive = this.isButtonInactive;
    this.buttonDisabled = this.isDisabled;
    this.buttonSubmitting = this.isSubmitting;
  }

  ngOnChanges(): void {
    this.buttonInactive = this.isButtonInactive;
    this.buttonDisabled = this.isDisabled;
  }
}
