import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { setSession } from '@app/shared/helpers/auth';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem('id_token');

    if (idToken) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + idToken)
      });
    }

    // todo: check do we need to keep this part since we call login on each request
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.token) {
            setSession(event.body.token);
          }
        }
        return event;
      }),
      catchError(x => this.handleAuthError(x))
    );
  }

  handleAuthError(err: HttpErrorResponse): Observable<any> {
    // we handle the failed login authentication in separate service (auth.service.ts)
    if (err.status === 401 && this.router.url !== '/app/customer/login') {
      this.authService.logout();
      return of(err.message);
    }
    return throwError(err);
  }
}
