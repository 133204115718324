<div class="popup">
    <button mat-icon-button class="popup__close-btn" mat-dialog-close>
        <mat-icon class="popup__close-btn--icon">close</mat-icon>
    </button>

    <div mat-dialog-content [ngClass]="data.cssClass">
        <span [innerHTML]="data.withTranslation ? (data.content | translate) : data.content"></span>
        <div class="popup__timer-countdown">
            <p class="popup__timer-countdown--text" [innerHTML]="data.countdownContent | translate"></p>
            <div class="popup__timer-countdown--animation">
                <div class="popup__timer-countdown--animation--progress-number">{{timer}}</div>
                <div class="popup__timer-countdown--animation--progress-fill" [style.width]="progressWidth+'%'"></div>
            </div>
        </div>
    </div>

    <div *ngIf="data && data.buttons && data.buttons.close" class="popup__timer--button">
        <button type="button" mat-flat-button class="popup__actions--button btn-yellow" 
            (click)="cancelTimer()"
            [innerHTML]="data.buttons.close | translate">
        </button>
    </div>
</div>