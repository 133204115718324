import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogModalButtons } from './enums/dialog-modal.enum';

@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss']
})
export class DialogPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      buttons?: any;
      cssClass?: string;
      content?: string;
      withTranslation?: boolean;
      closeBtn?: boolean;
      imgSrc?: string;
      imgSrc2?: string;
    },
    private dialogRef: MatDialogRef<DialogPopupComponent>
  ) {}

  cancel(): void {
    const { CANCEL } =  DialogModalButtons;
    this.dialogRef.close(CANCEL);
  }

  confirm(): void {
    const { CONFIRM } =  DialogModalButtons;
    this.dialogRef.close(CONFIRM);
  }
}
