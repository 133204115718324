import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { partner } from '@partners/partner';
import { ThemeName, ThemeMap, Theme } from '@themes/models/theme';

const LOCAL_STORAGE_THEME_KEY = 'gs_theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeBehaviorSubject: BehaviorSubject<Theme>;
  private themeObservable: Observable<Theme>;
  private themeSwitchingEnabled = false;

  constructor() {
    let theme: Theme = ThemeMap.get(partner.defaultTheme);

    this.themeSwitchingEnabled = partner.enableThemeSwitcher;

    if (this.themeSwitchingEnabled) {
      const storedThemeName: string = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
      if (storedThemeName !== null) {
        if (Object.values(ThemeName).includes(storedThemeName as ThemeName)) {
          const themeName: ThemeName = <ThemeName>storedThemeName;
          if (ThemeMap.has(themeName)) {
            theme = ThemeMap.get(themeName);
          }
        }
      }
    }

    this.themeBehaviorSubject = new BehaviorSubject<Theme>(theme);
    this.themeObservable = this.themeBehaviorSubject.asObservable();
  }

  getTheme(): Observable<Theme> {
    return this.themeObservable;
  }

  setTheme(themeName: ThemeName): void {
    if (this.themeSwitchingEnabled && ThemeMap.has(themeName)) {
      const theme: Theme = ThemeMap.get(themeName);
      localStorage.setItem(LOCAL_STORAGE_THEME_KEY, <string>themeName);
      this.themeBehaviorSubject.next(theme);
    }
  }
}
