<mat-form-field appearance="standard">
    <mat-label>{{config.label | translate}}</mat-label>
    <input type="text"
           #autocompleteTrigger
           matInput
           [formControl]="autoCompleteControl"
           [matAutocomplete]="auto"
           autocomplete="disable"
           (focusin)="onFocusInOut()"
           (focusout)="onFocusInOut()"
           matAutocompletePosition="above">
    <div *ngIf="autoCompleteControl.value" style="cursor: pointer" matSuffix aria-label="Clear" (click)="resetSearch()">
        <mat-icon>close</mat-icon>
    </div>
    <mat-autocomplete #auto="matAutocomplete">
       <mat-option *ngFor="let option of filteredOptions" [value]="option.text" (onSelectionChange)="loqateSelect(option)">
           <div class="address__item">
               <div class="address__item-text">
                   {{option.text}} <span style="font-size: 12px">{{option.description}}</span>
               </div>
               <div class="address__item-icon" *ngIf="!option.isAddress">
                   <mat-icon>chevron_right</mat-icon>
               </div>
           </div>
       </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="registrationForm.touched && registrationForm.invalid" [innerHTML]="(config.label | translate) + ' ' + ('error_is_required' | translate)"></mat-error>
</mat-form-field>
