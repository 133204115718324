import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { getSessionExpirationTime, removeSession, setSession, setTmpSession } from '@app/shared/helpers/auth';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@app/shared/services/notification.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SdkData } from '@app/shared/models/sdk-data';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { HelperService } from '@app/shared/services/helper.service';
import { DataShareService } from './data-share.service';
import { TerminalDataService } from './terminal-data.service';
import { DataFetchService } from '@app/shared/services/data-fetch.service';
import { TranslationService } from './translation.service';
import { PreloadService } from './preload.service';
import { ERROR_TERMINAL_INACTIVE, ERROR_TERMINAL_NOT_REGISTERED } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  environment: any;

  private isUserLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isUserLoggedIn$: Observable<boolean> = this.isUserLoggedInSubject.asObservable();

  constructor(
    private http: HttpClient,
    public projectConfigService: ProjectConfigService,
    public activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private dataShareService: DataShareService,
    private terminalDataService: TerminalDataService,
    private translationService: TranslationService,
    private dataFetchService: DataFetchService,
    private preloadService: PreloadService
  ) {
    this.environment = this.projectConfigService.getConfig();
  }

  getDongleId<Type>(): Observable<Type> {
    return this.http.get<Type>(`${this.environment.terminalHostUrl}/${this.environment.terminalSdkFull}`);
  }

  getQsVersion(): Observable<any> {
    return this.dataFetchService.specialGet<any>(
      `${this.environment.terminalHostUrl}/${this.environment.terminalGetQsVersion}`
    );
  }

  async login(): Promise<void> {
    try {
      // get dongle ID from machine
      const { sdkData } = await this.getDongleId<SdkData>().toPromise();
      if (!sdkData) {
        throw new Error('SDK key is missing');
      }

      // forward dongle ID to auth service and store new jwt token
      const { token, lang } = await this.callLoginApi(sdkData).toPromise();
      if (!token) {
        throw new Error('Token is missing');
      }
      setSession(token);
      if (!lang) {
        throw new Error('Language is missing');
      }
      this.translationService.setUserLanguage(lang, true);
      this.translationService.setDefault();

      await this.terminalDataService.fetchTerminalData();
    } catch (e) {
      if (e === ERROR_TERMINAL_INACTIVE) {
        throw ERROR_TERMINAL_INACTIVE;
      }
      const cachedTranslations = this.preloadService.getTranslationsFromCache('de');
      const errorMessage =
        cachedTranslations?.['terminal_not_registered'] || 'Fehler 10004: Das Gerät wurde noch nicht angemeldet.';

      this.notificationService.showMessage(errorMessage, 'error');
      throw ERROR_TERMINAL_NOT_REGISTERED;
    }
  }

  async EmailTokenLogin(): Promise<void> {
    try {
      // get token email from url
      const emailToken = this.helperService.getEmailTokenFromUrl();
      // authenticate the token with backend
      const response = await this.callEmailTokenLoginApi(emailToken).toPromise();
      if (!response) {
        throw new Error('Token is missing');
      }
      // store new jwt token and tmp session
      setSession(response.token);
      setTmpSession({
        id: response.customer_id,
        videoIdent: '0'
      });
    } catch (e) {
      const errorMessage = __('invalid_token_error');
      this.notificationService.showMessage(errorMessage, 'error');
      throw new Error('Token is missing');
    }
  }

  callLoginApi(dongleId: string): Observable<any> {
    // return this.http.post<any>(`${this.environment.hostUrl}/${this.environment.auth}`, {
    //   requester: dongleId
    // });
    return this.http.post<any>(`${this.environment.hostUrlDolphin}/${this.environment.authDolphin}`, {
      requester: dongleId
    });
  }

  callEmailTokenLoginApi(emailToken: string): Observable<any> {
    // return this.http.post<any>(`${this.environment.hostUrl}/${this.environment.mobileAuth}`, {
    //   requester: emailToken
    // });
    return this.http.post<any>(`${this.environment.hostUrlDolphin}/${this.environment.mobileAuthDolphin}`, {
      requester: emailToken
    });
  }
  setUserLoggedIn(isLoggedIn: boolean): void {
    this.isUserLoggedInSubject.next(isLoggedIn);
  }

  logout(): void {
    this.dataShareService.changePassword('');
    this.dataShareService.updateCasinoList([]);
    removeSession();
  }

  public isLoggedIn(): boolean {
    return moment().isBefore(getSessionExpirationTime());
  }
}
