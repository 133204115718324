import { KeyboardLayoutObject } from 'simple-keyboard/build/types/interfaces';

export default <KeyboardLayoutObject>{
  default: [
    'q w e r t y u i o p',
    'a s d f g h j k l',
    '{shift} z x c v b n m {backspace}',
    '{alt} {en} @ {space} . {enter}'
  ],
  alt: [
    '1 2 3 4 5 6 7 8 9 0',
    `_ / ! # $ & + [ ] < > $ \u00B4 ' "`,
    '* % ^ - + = ; : , ? ( ) {backspace}',
    '{ABC} {en} @ {space} . {enter}'
  ],
  num: ['1 2 3', '4 5 6', '7 8 9', '0 {backspace}'],
  shift: [
    'Q W E R T Y U I O P',
    'A S D F G H J K L',
    '{shift} Z X C V B N M {backspace}',
    '{alt} {en} @ {space} . {enter}'
  ]
};
