export interface SendSmsCodeResponse {
    status: string
}

export interface CheckSmsCodeResponse {
    valid: boolean
}

export interface SendSmsCodeErrorResponse {
    message: string
}

export enum SendSmsCodeEnum {
    OK = 'ok'
}