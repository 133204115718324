import { AbstractControl } from '@angular/forms';

export function clearError(control: AbstractControl, errorKey: string): void {
  if (control.hasError(errorKey)) {
    delete control.errors[errorKey];
    if (Object.keys(control.errors).length === 0) {
      control.setErrors(null);
    }
    control.updateValueAndValidity();
  }
}
