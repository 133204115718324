import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormInput } from '@app/shared/models/form-input';

@Component({
  selector: 'app-vs-select',
  templateUrl: './vs-select.component.html',
  styleUrls: ['./vs-select.component.scss']
})
export class VsSelectComponent implements OnInit {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;

  formControl = new FormControl('');

  ngOnInit(): void {
    if (this.config.default) {
      this.formControl.patchValue(this.config.default);
    }

    this.formControl.setValidators([this.config.required ? Validators.required : null]);

    this.registrationForm.setControl(this.config.key, this.formControl);
  }
}
