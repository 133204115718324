import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { infoTestLabelText } from '@app/shared/utils/constants';
@Component({
  selector: 'app-info-test-label',
  templateUrl: './info-test-label.component.html',
  styleUrls: ['./info-test-label.component.scss']
})
export class InfoTestLabelComponent implements OnInit {
  text = '';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.text = infoTestLabelText[this.translate.currentLang];
    this.translate.onLangChange.subscribe(data => {
      this.text = infoTestLabelText[data.lang];
    });
  }
}
