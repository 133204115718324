import { Component, Inject, OnInit } from '@angular/core';
import { Theme, ThemeName } from '@themes/models/theme';
import { ThemeService } from '@app/modules/layout/services/theme.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-theme-switcher-dialog',
  templateUrl: './theme-switcher-dialog.component.html',
  styleUrls: ['./theme-switcher-dialog.component.scss']
})
export class ThemeSwitcherDialogComponent implements OnInit {
  public theme: Theme;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      buttons?: any;
      cssClass?: string;
      content?: string;
      withTranslation?: boolean;
    },
    private themeService: ThemeService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.themeService.getTheme().subscribe(theme => (this.theme = theme));
  }

  switchToMerkurTheme(): void {
    this.themeService.setTheme(ThemeName.Merkur);
    this.dialog.closeAll();
  }

  switchToTipwinTheme(): void {
    this.themeService.setTheme(ThemeName.Tipwin);
    this.dialog.closeAll();
  }
}
