<div class="vs-button-btn">
  <button
    class="vs-button-btn__button"
    (click)="onClick()"
    [disabled]="isDisabled || timerStarted"
  >
    <span [innerHTML]="text"></span>
    <span *ngIf="timerStarted">({{ secondsRemaining$ | async }})</span>
  </button>
</div>
