// User authentication and session helper functions
export function setSession(token: string): void {
  localStorage.setItem('id_token', token);
  localStorage.setItem('expires_at', extractValueFromToken(token, 'exp'));
}

export function removeSession(): void {
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
  // remove tmp user session
  removeTmpSession();
}

export function getSessionExpirationTime(): number {
  const expiration = localStorage.getItem('expires_at');
  return JSON.parse(expiration) * 1000;
}
export function extractValueFromToken(rawToken: string, property = null): any {
  const base64Url = rawToken.split('.')[1];
  const decodedValue = JSON.parse(window.atob(base64Url));
  return decodedValue[property] || decodedValue;
}

export function setTmpSession(data: { id: string; videoIdent: string }): void {
  localStorage.setItem('customer_id', data.id);
  localStorage.setItem('customer_video_ident', data.videoIdent);
}

export function removeTmpSession(): void {
  localStorage.removeItem('customer_id');
  localStorage.removeItem('customer_video_ident');
  localStorage.removeItem('isCustomerVerified');
}
