import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { ServerIsNotAvailableErrorPageComponent } from './server-is-not-available-error-page.component';
import { HeaderModule } from '../layout/components/header/header.module';

@NgModule({
    declarations: [
        ServerIsNotAvailableErrorPageComponent,
    ],
    imports: [CommonModule, SharedModule, RouterModule, MatGridListModule, HeaderModule],
    exports: [
        ServerIsNotAvailableErrorPageComponent
    ]
})
export class ServerIsNotAvailableErrorPageModule {}
