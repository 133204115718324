<div clas="password-suggestion-card" (click)="sendRandomGeneratedPassword();">
    <div class="password-suggestion-card__row">
            <span>
                    <mat-icon>lock</mat-icon>
                    <span [innerHTML]="selectPasswordMessage | translate"></span>
            </span>
    </div>
    <div class="password-suggestion-card__row">
            <span>
                    {{randomPassword}}
            </span>
            <span (click)="refreshPassword(); $event.stopPropagation()">
                    <span [innerHTML]="refreshText | translate"></span>
                    <mat-icon>
                            refresh
                    </mat-icon>
            </span>
    </div>
    <div class="password-suggestion-card__row password-suggestion-card__footer" [innerHTML]="printInfo | translate">
    </div>
</div>