import { Component, Input, OnInit } from '@angular/core';
import { getErrorMessage } from '@app/shared/helpers/validator';
import { FormInput } from '@app/shared/models/form-input';

@Component({
  selector: 'app-vs-validation-errors',
  templateUrl: './vs-validation-errors.component.html',
  styleUrls: ['./vs-validation-errors.component.scss']
})
export class VsValidationErrorsComponent implements OnInit {
  @Input() config: FormInput;
  @Input() validationErrors: any;
  inputRequirements: string[];

  ngOnInit(): void {
    // filter out required validator from the list since it should be displayed separately
    this.inputRequirements = Object.keys(this.validationErrors).filter(item => {
      return item !== 'required';
    });
  }

  getErrorMessage(currentError: string): string {
    return getErrorMessage(currentError);
  }
}
