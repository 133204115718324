<div class="{{ theme.name }}-theme popup">
  <button class="popup__close-btn" mat-icon-button mat-dialog-close>
    <mat-icon class="popup__close-btn--icon">close</mat-icon>
  </button>

  <div mat-dialog-content [ngClass]="data.cssClass">
    <mat-grid-list cols="2">
      <mat-grid-tile>
        <a
          matRipple
          matRippleColor="rgb(255,255,255,30%)"
          class="theme-switcher-dialog-button"
          (click)="switchToMerkurTheme()"
        >
          Merkur
        </a>
      </mat-grid-tile>
      <mat-grid-tile>
        <a
          matRipple
          matRippleColor="rgb(255,255,255,30%)"
          class="theme-switcher-dialog-button"
          (click)="switchToTipwinTheme()"
        >
          Tipwin
        </a>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
