import { Component, Input, OnInit, OnChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormInput } from '@app/shared/models/form-input';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LoqateService } from '@app/modules/registration/services/loqate.service';
import { HelperService } from '@app/shared/services/helper.service';

@Component({
  selector: 'app-vs-address',
  templateUrl: './vs-address.component.html',
  styleUrls: ['./vs-address.component.scss']
})
export class VsAddressComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;
  @Input() isLoqateEnabled: boolean = true;
  addressForm: FormGroup;
  loqatePending = true;
  focusedInput: HTMLInputElement;

  constructor(
    private formBuilder: FormBuilder,
    private loqateService: LoqateService,
    private helperService: HelperService,
    private cdRef: ChangeDetectorRef
  ) {
    this.addressForm = this.formBuilder.group({});

    this.loqateService.addressDetails.subscribe(selectedAddress => {
      const isEmptyAddress = Object.values(selectedAddress).every(field => field.trim() === '');
      if (!isEmptyAddress) {
        this.addressForm.patchValue({ ...selectedAddress });
        this.addressForm.markAllAsTouched();
        this.loqateLoading(false);
      }
    });
  }

  ngOnInit(): void {
    this.addressForm.addControl('country', new FormControl('DEU')); // set country to DEU by default
    this.registrationForm.addControl('address', this.addressForm);
  }

  ngOnChanges(): void {
    if (!this.isLoqateEnabled) this.enterAddressManually();
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  resetFormAddress(): void {
    // reset search input
    this.addressForm.get('tmpSearch').patchValue('');
    this.addressForm.reset();
    this.addressForm.get('country').setValue('DEU'); // set country to DEU by default
    this.resetKeyboardInputs();
    this.loqateLoading(true);
  }

  resetKeyboardInputs(): void {
    this.helperService.clearKeyboardInput(true, this.config.key);
    this.config?.childFields?.forEach(field => {
      this.helperService.clearKeyboardInput(true, field.key);
    });
  }

  enterAddressManually(): void {
    this.addressForm.get('tmpSearch').patchValue(' ');
    this.loqateLoading(false);
  }

  loqateLoading(status: boolean): void {
    this.loqatePending = status;
  }
}
