import { Observable } from 'rxjs';
import { Params } from '@angular/router';

export function getUrlSegments(urlElements: any): any[] {
  return [urlElements.home, urlElements.country, urlElements.concern, urlElements.location].filter(segment => segment);
}

export function getUrlParams(snapshot: Params): Observable<Params> {
  let urlSnapshot = snapshot;
  while (urlSnapshot.children.length) {
    urlSnapshot = urlSnapshot.firstChild;
  }
  return urlSnapshot.params;
}

// generate settlement key to handle selected item in settlements dropdown
export function generateSettlementKey(settlement: { from: string; to: string }): string {
  return `${settlement.from}-${settlement.to}`.replace(/\s/g, '_');
}

export function generateRandomStrongPassword(length: number): string {
  // eslint-disable-next-line
  const _pattern = /[a-zA-Z0-9_\-.!@#\$%\^&\*]/;
  // eslint-disable-next-line
  const passwordStrength = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,14})');

  function getRandomByte(): number {
    if (window.crypto && window.crypto.getRandomValues) {
      const result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    }
    return Math.floor(Math.random() * 256);
  }

  function generatePassword(length: number): string {
    const res = [...Array(length)]
      .map(function () {
        let result;
        const exitCondition = true;
        while (exitCondition) {
          result = String.fromCharCode(getRandomByte());
          if (_pattern.test(result)) {
            return result;
          }
        }
      }, this)
      .join('');

    if (!passwordStrength.test(res)) {
      return generatePassword(length);
    }

    return res;
  }

  return generatePassword(length);
}
