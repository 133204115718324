import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PageReloadService } from '@app/shared/services/page-reload.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {
  allSubscriptions: Subscription[] = [];
  urlData: any;
  resetTimer: boolean | undefined;

  constructor(
    public activatedRoute: ActivatedRoute,
    public pageReload: PageReloadService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    // this.authService.login();

    this.urlData = { ...this.activatedRoute.snapshot.data };
    this.pageReload.setCurrentPage(this.urlData?.page);
    this.resetTimer  = this.urlData?.screenInactiveResetTimer;

    this.allSubscriptions.push(
      this.pageReload.screenInactiveTime$.subscribe(time => {
        if(this.resetTimer) {
          this.pageReload.updateLastInteractionTime();
          this.resetTimer = false;
          return;
        }

        this.pageReload.handleTimeoutCheck(this.urlData?.page, time);
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.allSubscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }

  navigateToHome(): void {
    this.pageReload.redirectToHome();
  }
}
