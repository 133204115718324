<form [formGroup]="phoneVerificationForm">
  <div class="form-group'">
    <div class="form-field">
      <div class="sms_text_image">
        <div class="sms_text_image_child2">
          <img *ngIf="!isEmailMode" src="assets/images/sms.png" />
          <img *ngIf="isEmailMode" src="assets/images/email.png" />
        </div>
      </div>
    </div>
    <ng-otp-input
      [config]="otpConfig"
      (click)="componentClicked()"
      id="ng-otp-reg"
      (focusin)="onFocus('in', $event)"
      (focusout)="onFocus('out', $event)"
    ></ng-otp-input>
  </div>

  <app-vs-button
    *ngIf="otpResend"
    (btnClick)="resendSmsCode()"
    [text]="'resend_sms_code_label' | translate"
    [hasCountdownBreak]="true"
    [countdownTimeInS]="60"
    style="display: flex; justify-content: center"
  >
  </app-vs-button>
</form>
