<div class="info-box">
    <div *ngIf="boxContent.stepNumber" class="info-box__step-nb">
        {{boxContent.stepNumber}}
    </div>
    <div class="info-box__content">
        <div [innerHTML]="boxContent.infoText | translate" class="info-box__title"></div>
        <div *ngIf="boxContent.appQrCode" #appQrCodePlaceHolder></div>
        <div *ngIf="boxContent.topImg" (click)="boxContent.link && navigateTo(boxContent.link)">
            <img class="info-box__topImg" [src]="boxContent.topImg | safeUrl">
        </div>
        <div *ngIf="boxContent.bottomImg">
            <img class="info-box__bottomImg" [src]="boxContent.bottomImg">
        </div>
        <div *ngIf="boxContent.bottomSplit" class="info-box__bottomSplit">
            <img class="info-box__bottomSplit--img1" [src]="boxContent.bottomSplit.firstSrc | safeUrl">
            <img class="info-box__bottomSplit--img2" [src]="boxContent.bottomSplit.secondSrc | safeUrl">
        </div>
        <div *ngIf="boxContent.contentMessage || boxContent.contentMessageTranslated" class="info-box__contentMessage" [innerHTML]="boxContent.contentMessageTranslated ?? (boxContent.contentMessage | translate)">
        </div>
    </div>
    <div *ngIf="boxContent.qrCodeContainer" class="info-box__qr-code-container">
        <div *ngIf="boxContent.qrCodeContainer.qr" #containerQrCodePlaceHolder
             class="info-box__qr-code-container--uri"></div>
        <div *ngIf="!boxContent.qrCodeContainer.qr" (click)="boxContent.link && navigateTo(boxContent.link)"
             class="info-box__qr-code-container--uri">
            <img class="info-box__qr-code-container--img" [src]="boxContent.qrCodeContainer.uri ">
        </div>
        <div [innerHTML]="boxContent.qrCodeContainer.text | translate"></div>
    </div>
</div>
