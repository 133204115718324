import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { VisionLoaderFactory } from '@app/shared/helpers/translation-loader';
import { TranslationService } from '@app/shared/services/translation.service';
import { MyMissingTranslationHandler } from '@app/shared/helpers/missing-translation-handler';
import { TerminalDataService } from '../services/terminal-data.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: VisionLoaderFactory,
        deps: [HttpClient, ProjectConfigService]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      }
    })
  ],
  exports: [TranslateModule]
})
export class TranslationModule {
  constructor(private pageTranslation: TranslationService, private terminalDataService: TerminalDataService) {
    this.terminalDataService.isTerminalDataSet.subscribe(loaded => {
      if (loaded) {
        const languageList = pageTranslation.getLanguageList();
        const terminalData = this.terminalDataService.getTerminalData();
        const defaultLangIso = languageList?.find(lang => lang?.languageId === terminalData['defaultLanguage'])?.iso;
        pageTranslation.setUserLanguage(defaultLangIso, true);
        pageTranslation.setDefault();
      }
    });
  }
}
