import { Injectable } from '@angular/core';
import { PopupComponent } from '@app/shared/components/popup/popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Popup, ScreenTimeoutPopup } from '@app/shared/models/popup';
import { LangSwitcherComponent } from '@app/modules/layout/components/lang-switcher/lang-switcher.component';
import { AgbPopupComponent } from '@app/modules/customer/components/video-ident/agb-popup/agb-popup.component';
import { ScreenTimeoutPopupComponent } from '../components/screen-timeout-popup/screen-timeout-popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  constructor(public dialog: MatDialog, private http: HttpClient) {}

  /**
   * Open help/agb/cancel-registration dialog
   *
   * @params {content: Popup} popup Setting
   */
  openDialog(popupSetting: Popup): MatDialogRef<PopupComponent> {
    return this.dialog.open(PopupComponent, popupSetting);
  }

  /**
   * Open language switcher dialog
   *
   * @params {content: Popup} popup Setting
   */
  openLanguageSwitcherDialog(popupSetting: Popup): void {
    this.dialog.open(LangSwitcherComponent, popupSetting);
  }

  /**
   * Open language switcher dialog
   *
   * @params {content: Popup} popup Setting
   */
  openIdNowAgbDialog(popupSetting: Popup): void {
    this.dialog.open(AgbPopupComponent, popupSetting);
  }

  /**
   * get the file content from assets folder via http protocol / used to get agb static text from assets
   *
   * @params {path: string} path to the file
   */
  getFileContent(path: string): Observable<string> {
    return this.http.get(path, { responseType: 'text' });
  }

  /**
   * Open screen timeout dialog
   *
   * @params {content: ScreenTimeoutPopup} popup Setting
   */
  openScreenTimeoutDialog(popupSetting: ScreenTimeoutPopup): MatDialogRef<ScreenTimeoutPopupComponent> {
    return this.dialog.open(ScreenTimeoutPopupComponent, popupSetting);
  }
}
