<div class="welcome-page">
  <div class="welcome-page--logo">
    <img class="welcome-page--logo--image" src="assets/logo/merkur-home-logo.png" alt="Merkur Casino" />
  </div>
  <div class="welcome-page--container">
    <p [innerHTML]="(succes ? 'mobile_welcome_message' : 'mobile_welcome_invalid_token') | translate"></p>
  </div>
  <app-vs-continue-button *ngIf="succes" (click)="navigateToNextScreen()"></app-vs-continue-button>
  <div class="casino-multilog-logo">
    <img src="./assets/logo/logo-multilog.png" alt="" srcset="">
  </div>
</div>