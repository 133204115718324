import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectedBillData } from '@app/modules/paylado/models/selected-bill-data';
import { AvailableBillData } from '@app/modules/paylado/models/available-bill-data';
import { CurrencySymbolEnum } from '@app/modules/paylado/enums/currency.enum';

@Component({
  selector: 'app-banknote-box',
  templateUrl: './banknote-box.component.html',
  styleUrls: ['./banknote-box.component.scss']
})
export class BanknoteBoxComponent implements OnInit {
  @Output() public totalAmountChange: EventEmitter<SelectedBillData> = new EventEmitter<SelectedBillData>();
  @Input() billConfig: AvailableBillData;
  counter = 0;
  canDecrease = false;
  currencySymbol: string;

  ngOnInit(): void {
    this.billConfig.canIncrease = true;
    this.currencySymbol = CurrencySymbolEnum[this.billConfig.currency];
    if (!this.billConfig.amount) {
      this.billConfig.canIncrease = false;
    }
  }

  updateAmount(action: string): void {
    switch (action) {
      case 'increase':
        if (this.billConfig.canIncrease) {
          this.counter++;
          this.billConfig.nbOfSelectedBills = this.counter;
          this.totalAmountChange.emit({ value: this.billConfig.type, numberOfSelectedBills: this.counter });
          this.canDecrease = true;
          if (this.billConfig.amount === 0 || this.counter === this.billConfig.level) {
            this.billConfig.canIncrease = false;
          }
        }
        break;
      case 'decrease':
        if (this.canDecrease) {
          this.counter--;
          this.billConfig.nbOfSelectedBills = this.counter;
          this.totalAmountChange.emit({ value: -this.billConfig.type, numberOfSelectedBills: this.counter });
          this.canDecrease = !(this.counter === 0);
          this.billConfig.canIncrease = true;
        }
        break;
    }
  }
}
