<mat-form-field
  class="date-picker"
  appearance="standard"
  [formGroup]="registrationForm"
  [ngClass]="{
    'valid-input': (formControl.valid || formControl.disabled) && formControl.value
  }"
>
  <mat-label>{{ config.label | translate }}</mat-label>
  <input
    matInput
    [formControlName]="config.key"
    (focusin)="onFocus()"
    [matDatepicker]="picker"
    [disabled]="isPrefilled"
    [min]="minDate"
    [max]="maxDate"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    startView="multi-year"
    (closed)="onDatePickerClose()"
    restoreFocus="false"
    [startAt]="startDate"
  ></mat-datepicker>
  <mat-error *ngIf="formControl.invalid" [innerHTML]="getErrorMessage() | translate"></mat-error>
</mat-form-field>
