import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CasinoData } from '../models/casino-data';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  private passwordSource = new BehaviorSubject('');
  currentPassword = this.passwordSource.asObservable();

  private casinosSource = new BehaviorSubject<CasinoData[]>([]);
  private casinosToPrintSource = new BehaviorSubject<CasinoData[]>([]);
  private casinosToSend$ = new BehaviorSubject<CasinoData[]>([]);

  casinos = this.casinosSource.asObservable();
  casinosToPrint = this.casinosToPrintSource.asObservable();
  casinosToSend = this.casinosToSend$.asObservable();

  private selectedOfferSource = new BehaviorSubject<string>('');
  selectedOffer = this.selectedOfferSource.asObservable();

  private payladoIsSelected$ = new BehaviorSubject<boolean>(false);
  payladoIsSelected = this.payladoIsSelected$.asObservable();

  updateCasinoList(casinos: CasinoData[]): void {
    this.casinosSource.next(casinos);
  }

  updateCasinosToPrint(casinos: CasinoData[]): void {
    this.casinosToPrintSource.next(casinos);
  }

  updateCasinosToSend(casinos: CasinoData[]): void {
    this.casinosToSend$.next(casinos);
  }

  changePassword(password: string): void {
    this.passwordSource.next(password);
  }

  setSelectedOffer(offerId: string): void {
    this.selectedOfferSource.next(offerId);
  }

  setPayladoIsSelected(isSelected: boolean): void {
    this.payladoIsSelected$.next(isSelected);
  }
}
