import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PageReloadService } from '@app/shared/services/page-reload.service';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  public content;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      buttons?: any;
      cssClass?: string;
      content?: string;
      withTranslation?: boolean;
      additionalContent?: string;
      additionalContentValue?: string;
    },
    private pageReload: PageReloadService,
    private dialogRef: MatDialog
  ) {}

  ngOnInit(): void {}
  /**
   * Close all popups and navigate to main menu
   */
  navigateToHome(): void {
    this.dialogRef.closeAll();
    this.pageReload.redirectToHome();
  }
}
