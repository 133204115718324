import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslationService } from '@app/shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {
  languages: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      buttons?: any;
      cssClass?: string;
      content?: string;
      withTranslation?: boolean;
    },
    private translationService: TranslationService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.languages = this.translationService.getLanguageList();
  }

  setLanguage(languageIso: string): void {
    this.translate.use(languageIso);
    this.translationService.setUserLanguage(languageIso);
    this.dialog.closeAll();
  }
}
