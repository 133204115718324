<form [formGroup]="addressForm">
    <div [ngStyle]="{'display': loqatePending ? 'block' : 'none'}">
        <app-vs-autocomplete-select [registrationForm]="addressForm" [config]="config"></app-vs-autocomplete-select>
        <div class="address__example-text" [innerHTML]="'example_address_text' | translate">
        </div>
        <div class="address__info-message" (click)="enterAddressManually()">
            <span class="address__info-message-click-here" [innerHTML]="'click_here' | translate"></span>&nbsp;
            <span [innerHTML]="'manually_enter_address' | translate"></span>
        </div>
    </div>
    <div [class]="{'form-group': true, 'form-group--hidden': loqatePending}">
        <div *ngFor="let fieldConfig of config.childFields" class="form-field">
            <div [ngSwitch]="fieldConfig.type">
                <app-vs-select [registrationForm]="addressForm"
                               *ngSwitchCase="'select'"
                               [config]="fieldConfig">
                </app-vs-select>
                <app-vs-input
                        [registrationForm]="addressForm"
                        *ngSwitchDefault
                        [config]="fieldConfig">
                </app-vs-input>
            </div>
        </div>
        <div class="address__info-message" *ngIf="isLoqateEnabled" (click)="resetFormAddress()">
            <span class="address__info-message-click-here" [innerHTML]="'click_here' | translate"></span>&nbsp;
            <span [innerHTML]="'empty_address_form' | translate"></span>
        </div>
    </div>
</form>
