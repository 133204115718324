import { MerkurTheme } from '@themes/theme.merkur';
import { TipwinTheme } from '@themes/theme.tipwin';

export interface Theme {
  name: string;
  headerHomeIcon: string;
  headerInfoIcon: string;
  payladoHeaderLogo: string;
  cashAcceptorArrowDown: string;
}

export enum ThemeName {
  Merkur = 'merkur',
  Tipwin = 'tipwin'
}

export const ThemeMap = new Map<ThemeName, Theme>([
  [ThemeName.Merkur, MerkurTheme],
  [ThemeName.Tipwin, TipwinTheme]
]);
