import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { globalConfig } from '@config/config.global';
import { environment } from '@env/environment';
import { localConfig } from '@config/config.local';
import { staticTranslations } from '@translations/static-translation-keys';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class VisionTranslateLoader implements TranslateLoader {
  environment: any;
  translationsKeys: string[];

  constructor(private http: HttpClient, private config: ProjectConfigService) {
    config.initConfig({
      ...globalConfig,
      ...environment,
      ...localConfig
    });
    this.translationsKeys = staticTranslations;
    this.environment = config.getConfig();
  }

  /**
   * Gets the translations from the server
   */
  getTranslation(lang: string): Observable<any> {
    // const translationsApi = `${this.environment.hostUrl}${this.environment.translationApi}`;
    const translationsApi = `${this.environment.hostUrlDolphin}${this.environment.translationApiDolphin}/${lang}`;
    // const payload = {
    //   lang,
    //   translations: this.translationsKeys
    // };
    // const translatedKeys = this.http.post<any>(translationsApi, payload);
    // const translatedKeys = this.http.get<any>(translationsApi);
    // return translatedKeys.pipe(map(response => response.data));
    return this.http.get<any>(translationsApi).pipe(
      map(response => {
        const translatedData = response.data || {};

        this.translationsKeys.forEach(key => {
          if (!translatedData[key]) {
            translatedData[key] = key;
          }
        });

        return translatedData;
      })
    );
  }
}

export function VisionLoaderFactory(http: HttpClient, config: ProjectConfigService): any {
  return new VisionTranslateLoader(http, config);
}
