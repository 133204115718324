import { KeyboardLayoutObject } from 'simple-keyboard/build/types/interfaces';

export default <KeyboardLayoutObject>{
  default: [
    'q w e r t y u ı o p ğ ü',
    'a s d f g h j k l ş i',
    '{shift} z x c v b n m ö ç {backspace}',
    '{alt} {tr} @ {space} . {enter}'
  ],
  alt: [
    '1 2 3 4 5 6 7 8 9 0',
    `é ~ _ / ! # $ & + [ ] < > $ \u00B4 ' "`,
    '* % ^ - + = ; : , ? ( ) {backspace}',
    '{ABC} {tr} @ {space} . {enter}'
  ],

  num: ['1 2 3', '4 5 6', '7 8 9', '0 {backspace}'],
  shift: [
    'Q W E R T Y U I O P Ğ Ü',
    'A S D F G H J K L Ş İ',
    '{shift} Z X C V B N M Ö Ç {backspace}',
    '{alt} {tr} @ {space} . {enter}'
  ]
};
