import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { Router } from '@angular/router';
import { CustomerService } from '@app/modules/customer/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { DataShareService } from '@app/shared/services/data-share.service';
import { TranslationService } from '@app/shared/services/translation.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {
  showContent = false;
  succes: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private customerService: CustomerService,
    private helperService: HelperService,
    private dataShareService: DataShareService,
    private translationService: TranslationService
  ) {
    this.translationService.setUserLanguage('de');
  }

  ngOnInit(): void {
    this.authService
      .EmailTokenLogin()
      .then(() => {
        this.succes = true;
        localStorage.setItem('isCustomerVerified', '1');
      })
      .catch(() => {
        this.succes = false;
      });
  }

  navigateToNextScreen(): void {
    const emailToken = this.helperService.getEmailTokenFromUrl();

    const url = '/app/customer/casinos-agb';
    this.router.navigate([url], {
      queryParams: { token: emailToken },
      queryParamsHandling: 'merge'
    });
  }
}
