import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormInput } from '@app/shared/models/form-input';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { checkAllowedAge } from '@app/modules/registration/customValidators';
import { getErrorMessage } from '@app/shared/helpers/validator';
import { HelperService } from '@app/shared/services/helper.service';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { AGE_18_YEARS_IN_MS } from '@app/modules/registration/utils/constants';

@Component({
  selector: 'app-vs-date-picker',
  templateUrl: './vs-date-picker.component.html',
  styleUrls: ['./vs-date-picker.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' }]
})
export class VsDatePickerComponent implements OnInit {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;
  @Input() isPrefilled: boolean;
  startDate = new Date(1992, 0, 1);
  minDate = new Date();
  maxDate = new Date();
  formControl = new FormControl('');
  @ViewChild('picker') datePicker: MatDatepicker<Date>;

  /**
   *
   */
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    const validators = [];
    if (this.config.ageRestricted) {
      validators.push(Validators.compose([checkAllowedAge()]));
    }

    this.formControl.setValidators(validators);

    this.registrationForm.addControl(this.config.key, this.formControl);

    this.formatDateRange();
  }

  formatDateRange(): void {
    this.minDate.setFullYear(this.minDate.getFullYear() - 100);
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    const allowedAge = AGE_18_YEARS_IN_MS;
    const birthDate = new Date(moment(this.maxDate).format('YYYY-MM-DD')).getTime();
    const todayDate = new Date(moment(Date.now()).format('YYYY-MM-DD')).getTime();
    todayDate - birthDate > allowedAge
      ? this.maxDate.setDate(this.maxDate.getDate())
      : this.maxDate.setDate(this.maxDate.getDate() - 1);
  }

  onFocus(): void {
    this.datePicker.open();
    this.helperService.showKeyboard(false);
  }

  onDatePickerClose(): void {
    this.helperService.showKeyboard(true);
  }

  /**
   * Returns validation error message according to the current thrown error.
   *
   * @returns Validation error message.
   */
  getErrorMessage(): string {
    const currentError = Object.keys(this.formControl.errors)[0];
    return getErrorMessage(currentError);
  }
}
