import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '@app/shared/services/helper.service';

@Component({
  selector: 'app-loading-popup',
  templateUrl: './printer-status-popup.component.html',
  styleUrls: ['./printer-status-popup.component.scss']
})
export class PrinterStatusPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { content?: string; imgSrc?: string; spinner?: boolean; retryButton?: boolean; closeBtn?: boolean },
    private helperService: HelperService
  ) {}

  retryRequest(): void {
    this.helperService.retryRequestClicked.next();
  }
}
