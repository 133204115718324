import { Injectable } from '@angular/core';
import { CustomerService } from '@app/modules/customer/services/customer.service';
import { PrinterStatusPopupComponent } from '@app/modules/customer/components/printer-status-popup/printer-status-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { HttpClient } from '@angular/common/http';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { DataFetchService } from '@app/shared/services/data-fetch.service';
import { TranslateService } from '@ngx-translate/core';
import { DataShareService } from '@app/shared/services/data-share.service';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import { CasinoData } from '@app/shared/models/casino-data';
import { PrinterResponse } from '@app/modules/customer/models/printer-response';
import { pollDataOnResolve } from '@app/shared/helpers/poll-data-on-resolve';
import { partner } from '@partners/partner';
import { PayladoTransactionInfo, TransactionTypeEnum } from '@app/modules/paylado/models/transaction-info';
import { take, timeout } from 'rxjs/operators';
import { PRINTER_TIMEOUT } from '@app/modules/registration/utils/constants';
import { HelperService } from '@app/shared/services/helper.service';
import {
  Printout,
  PrintoutToPrint,
  PrintoutLogo,
  PrintType,
  PrintSizeHeight,
  PrintAlignment,
  PrintSizeWidth,
  PrintEmphasis
} from '@app/shared/models/printout';
import { TerminalDataService } from '@app/shared/services/terminal-data.service';
@Injectable({
  providedIn: 'root'
})
export class PrinterService extends DataFetchService {
  translations: { [key: string]: string };
  textToPrint = '';
  printPayload = <Printout>{};

  private printerSub: Subscription = null;

  private isPrintingSubject = new BehaviorSubject<boolean>(false);
  isPrinting = this.isPrintingSubject.asObservable();
  private enablePrinterSource = new BehaviorSubject(false);
  enablePrinter = this.enablePrinterSource.asObservable();
  public failedPrintDialog: MatDialogRef<PrinterStatusPopupComponent>;
  private clickSub: Subscription = null;
  public showCloseButton: boolean = false;
  public customerSub: Subscription = null;
  customer: any = {};

  constructor(
    private dialog: MatDialog,
    public customerService: CustomerService,
    public http: HttpClient,
    public config: ProjectConfigService,
    public translate: TranslateService,
    public dataShareService: DataShareService,
    public helperService: HelperService,
    public terminalDataService: TerminalDataService
  ) {
    super(http, config);
    this.translate
      .get([
        'printout_success_text',
        'printout_failed_text',
        'printout_pending_text',
        'paylado_text_to_print',
        'password',
        'printout_welcome_title',
        'printout_password_text',
        'printout_support_text',
        'printout_registration_success_text',
        'printout_transaction_header_text',
        'printout_transaction_pay_out_text',
        'printout_transaction_pay_out_failed_text',
        'printout_transaction_pay_in_text',
        'printout_transaction_id_text',
        'printout_transaction_terminal_id_text',
        'po_header',
        'po_intro',
        'po_list_offers_intro',
        'po_list_offers_outro',
        'po_pwd_intro',
        'po_outro',
        'po_support',
        'po_support_information',
        'po_qr',
        'po_terminal_id',
        'po_payout_header',
        'po_payout_intro',
        'po_timestamp',
        'po_terminal',
        'Terminal_ID',
        'po_transaction_id',
        'Transaction_ID',
        'po_payout_amount',
        'Amount_Value',
        'po_paylado_support',
        'po_payin_header',
        'po_payin_intro',
        'po_payin_amount'
      ])
      .subscribe(response => {
        this.translations = response;
      });
    this.printPayload.toPrint = [];
  }

  /**
   * Call Print endpoint from quicksilver
   *
   * @params {payload: Object} customer id
   *
   * @returns Observable
   */

  printData(payload = {}): Observable<any> {
    return this.post<PrinterResponse>(`${this.environment.terminalHostUrl}/${this.environment.terminalPrintData}`, {
      ...payload
    });
  }

  /**
   * Format full printout text
   *
   * @params {casinos: CasinoData[]} Casinos to be printed
   *
   * @returns full text to be printed
   */
  formatPrintoutText(casinos: CasinoData[]): string {
    this.concatTitle();
    this.concatRegistrationSuccessInfo();
    this.concatCasinoStatus(casinos);
    this.concatPassword();
    this.concatPayladoInfo();
    this.concatSupportInfo();
    this.printPayload.toPrint.push({ type: 'qrcode', link: 'http://onelink.to/paylado' });
    return this.textToPrint;
  }

  formatPayinPrintout(transactionData: PayladoTransactionInfo): void {
    const { terminalId, transactionId, amount, dateTime, transactionType } = transactionData;

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_payin_header + '\n\n',
      sizeHeight: 1,
      sizeWidth: 1,
      alignment: 'Center'
    });

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_payin_intro + '\n\n',
      alignment: 'Left'
    });

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_timestamp + '\n\n',
      alignment: 'Left'
    });

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_terminal + ': ',
      alignment: 'Left',
      sizeHeight: 1,
      sizeWidth: 1
    });

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_terminal_id + '\n\n',
      alignment: 'Left'
    });

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_transaction + ': ',
      alignment: 'Left',
      sizeHeight: 1,
      sizeWidth: 1
    });

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_transaction_id + '\n\n',
      alignment: 'Left'
    });

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_payout_amount + ': ',
      alignment: 'Left',
      sizeHeight: 1,
      sizeWidth: 1
    });

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_payout_amount_value + '\n\n',
      alignment: 'Left'
    });

    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.po_paylado_support + '\n\n',
      alignment: 'Center'
    });

    this.printPayload.toPrint.push({
      type: 'qrcode',
      link: this.translations.po_qr,
      alignment: 'Center'
    });
  }
  /**
   * Concat the title on the top of the printed text
   */
  concatTitle(): void {
    this.textToPrint = this.textToPrint.concat(this.translations.printout_welcome_title + '\n\n');
    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.printout_welcome_title + '\n\n'
    });
  }
  /**
   * Concat the title on the top of the printed text
   */
  concatRegistrationSuccessInfo(): void {
    this.textToPrint = this.textToPrint.concat(this.translations.printout_registration_success_text + '\n');
    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.printout_registration_success_text + '\n'
    });
  }

  /**
   * Concat each casino status to the printed text
   *
   * @params {casinos: CasinoData[]} array of casinos to be printed
   *
   */
  concatCasinoStatus(casinos: CasinoData[]): void {
    let allText = '\n';
    for (const casino of casinos) {
      let casinoText = '';
      casinoText = this.getTextByRegistrationStatus(casino.registration_status, casino.name, casino.support_email);
      allText = allText.concat(casino.internet_address) + '\n \n';
      allText = allText.concat(casinoText) + '\n \n';
    }

    this.textToPrint = this.textToPrint.concat(allText);
    this.printPayload.toPrint.push({
      type: 'text',
      data: allText
    });
  }

  getTextByRegistrationStatus(registration_status: string, casino_name: string, support_email: string): string {
    switch (registration_status) {
      case 'done':
        return this.setCasinoNameIntoText(this.translations.printout_success_text, casino_name, support_email);
      case 'rejected':
        return this.setCasinoNameIntoText(this.translations.printout_failed_text, casino_name, support_email);
      default:
        return this.setCasinoNameIntoText(this.translations.printout_pending_text, casino_name, support_email);
    }
  }

  /**
   * Concat user password to the text to be printed
   */
  concatPassword(): void {
    let passwordToPrint = '';
    this.dataShareService.currentPassword.subscribe(password => (passwordToPrint = password));
    this.textToPrint = this.textToPrint.concat(
      this.translations.printout_password_text + ' ' + passwordToPrint + '\n \n'
    );
  }

  /**
   * Concat paylado information to the text to be printed
   */
  concatPayladoInfo(): void {
    this.textToPrint = this.textToPrint.concat(this.translations.paylado_text_to_print + '\n \n');
    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.paylado_text_to_print + '\n \n'
    });
  }

  /**
   * Concat support information to the text to be printed
   */
  concatSupportInfo(): void {
    this.textToPrint = this.textToPrint.concat(this.translations.printout_support_text);
    this.printPayload.toPrint.push({
      type: 'text',
      data: this.translations.printout_support_text
    });
  }

  /**
   * Replace <casino> tag with casino name into the translated texts
   *
   * @params {casinoText: string} translated text with <casino> tag on it
   * @params {casino: Object} the casino name
   *
   * @returns casino text with casino name on it
   */
  setCasinoNameIntoText(casinoText: string, casino: string, support_email: string): string {
    return casinoText.replace(/<casino>/g, casino).replace(/<support_email>/g, support_email);
  }

  /**
   * Open Success popup if the printer works and data being printed
   *
   */
  openSuccessPrintPopup(): MatDialogRef<PrinterStatusPopupComponent> {
    return this.dialog.open(PrinterStatusPopupComponent, {
      width: '50%',
      height: '50%',
      panelClass: 'popup',
      disableClose: true,
      data: {
        content: __('success_print_data'),
        imgSrc: './assets/icons/done.png'
      }
    });
  }

  /**
   * Open fail popup if the printer has error
   *
   */
  openFailedPrintPopup(closeEnabled?: boolean): MatDialogRef<PrinterStatusPopupComponent> {
    return this.dialog.open(PrinterStatusPopupComponent, {
      width: '50%',
      height: '50%',
      panelClass: 'popup',
      disableClose: true,
      data: {
        content: __('failed_print_data'),
        imgSrc: './assets/icons/not_ok.png',
        retryButton: true,
        closeBtn: closeEnabled
      }
    });
  }

  /**
   * Open loading popup while the data are being processed (poll backend, format text, request quicksilver)
   *
   */
  openLoadingDataDialog(): MatDialogRef<PrinterStatusPopupComponent> {
    return this.dialog.open(PrinterStatusPopupComponent, {
      width: '50%',
      height: '50%',
      panelClass: 'popup',
      disableClose: true,
      data: {
        content: __('data_processing_printer'),
        spinner: true
      }
    });
  }

  enablePrintout(enable: boolean): void {
    this.enablePrinterSource.next(enable);
  }

  /**
   * Check each casino's registration status
   *
   * @remarks
   * Unchecked casinos are filtered out from printing
   * Poll wanda for minimum 2 requests and maximum 6 requests.
   * Time between each request is 1 second
   * If checked casino still has "sent" or "open" status after the 6th request it will consider as "pending"
   *
   */
  preparePrintingData(): void {
    this.dialog.closeAll();
    if (this.clickSub) {
      this.clickSub?.unsubscribe();
    }

    const customerId = localStorage.getItem('customer_id');
    const payload = {
      customer_id: customerId
    };

    this.printerSub = this.customerService
      .getCasinosAgbData(payload)
      .pipe(timeout(PRINTER_TIMEOUT))
      .subscribe(
        response => {
          this.dataShareService.updateCasinoList(response.casinos);

          let checkedCasinos: CasinoData[] = [];
          const checkedCasinosSub = this.dataShareService.casinosToPrint.subscribe(casinos => {
            checkedCasinos = casinos;
            this.dynPrintoutRegistration();
          });
        },
        error => {
          this.printerSub.unsubscribe();
        }
      );
  }

  handleTimeout(): void {
    const failed = this.openFailedPrintPopup(this.showCloseButton);
    this.clickSub = this.helperService.retryRequestClicked.subscribe(() => {
      this.showCloseButton = true;
      this.printerSub.unsubscribe();
      failed.close();
      this.preparePrintingData();
    });
  }

  /**
   * Call the endpoint from quicksilver and print data.
   * Shows overlay with the printer status for 3 seconds.
   *
   * @params {casinos: array} array of casinos to be printed
   *
   */
  printDataBis(): void {
    const payload: Printout = this.printPayload;
    this.isPrintingSubject.next(true);
    this.printData(payload)
      .pipe(timeout(PRINTER_TIMEOUT))
      .subscribe(
        response => {
          if (response.status === 'OK') {
            this.openSuccessPrintPopup();
            setTimeout(() => {
              this.isPrintingSubject.next(false);
              this.dialog.closeAll();
            }, 3000);
          }

          if (response.status === 'Error') {
            this.isPrintingSubject.next(false);
            this.openFailedPrintPopup(this.showCloseButton);
            this.clickSub = this.helperService.retryRequestClicked.subscribe(() => {
              this.showCloseButton = true;
              this.printerSub?.unsubscribe();
              this.dialog.closeAll();
              this.preparePrintingData();
            });
          }

          this.dataShareService.changePassword('');
        },
        () => {
          this.openFailedPrintPopup(this.showCloseButton);
          this.clickSub = this.helperService.retryRequestClicked.subscribe(() => {
            this.showCloseButton = true;
            this.printerSub?.unsubscribe();
            this.dialog.closeAll();
            this.preparePrintingData();
          });
        }
      );
  }

  /**
   * Format full Paylado Transaction info printout text
   *
   * @params {transactionData: PayladoTransactionInfo} Transaction info to be printed
   *
   * @returns full text to be printed
   */
  formatPayladoTransactionText(transactionData: PayladoTransactionInfo): string {
    this.textToPrint = '';
    this.concatPayladoTransactionInfo(transactionData);

    return this.textToPrint;
  }

  /**
   * Concat paylado information to the text to be printed
   * @params {transactionData: PayladoTransactionInfo} Object of transaction info to be printed
   */
  concatPayladoTransactionInfo(transactionData: PayladoTransactionInfo) {
    const { terminalId, transactionId, amount, dateTime, transactionType } = transactionData;
    const transactionHeaderLabel = this.translations.printout_transaction_header_text;
    const transactionPayOutLabel = this.translations.printout_transaction_pay_out_text;
    const transactionPayOutFailedLabel = this.translations.printout_transaction_pay_out_failed_text;
    const transactionPayInLabel = this.translations.printout_transaction_pay_in_text;
    const transactionIdLabel = this.translations.printout_transaction_id_text;
    const transactiontTerminalIdLabel = this.translations.printout_transaction_terminal_id_text;

    const transactionTypeLabel =
      transactionType === TransactionTypeEnum.PAY_OUT
        ? transactionPayOutLabel
        : TransactionTypeEnum.PAY_OUT_FAILED
        ? transactionPayOutFailedLabel
        : transactionPayInLabel;

    const lineWidth = 48;
    const dashLine = '-'.repeat(lineWidth);
    const breakLine = this.textToPrint.concat(dashLine + '\n\n');

    const concatHeader = () => {
      this.textToPrint = this.textToPrint.concat(breakLine);
      this.textToPrint = this.textToPrint.concat(transactionHeaderLabel + '\n\n');
      this.textToPrint = this.textToPrint.concat(breakLine);
    };

    const concatDateTime = () => {
      if (dateTime) this.textToPrint = this.textToPrint.concat(dateTime + '\n\n');
    };

    const concatTerminalId = () => {
      if (terminalId) this.textToPrint = this.textToPrint.concat(transactiontTerminalIdLabel + ' ' + terminalId + '\n');
    };

    const concatTransactionId = () => {
      if (transactionId) this.textToPrint = this.textToPrint.concat(transactionIdLabel + ' ' + transactionId + '\n');
    };

    const concatAmount = () => {
      this.textToPrint = this.textToPrint.concat(breakLine);
      if (amount) this.textToPrint = this.textToPrint.concat(transactionTypeLabel + ' ' + amount + '\n\n');
      this.textToPrint = this.textToPrint.concat(breakLine);
    };

    concatHeader();
    concatDateTime();
    concatTerminalId();
    concatTransactionId();
    concatAmount();
  }

  /**
   * Delete text to be printed
   */
  resetTextToPrint(): void {
    this.textToPrint = '';
  }

  dynPrintoutRegistration() {
    const customerId = localStorage.getItem('customer_id');
    const customersub = this.customerService
      .getUserData({
        id: customerId,
        attributes: ['first_name']
      })
      .pipe(take(1));
    const casinosub = this.dataShareService.casinosToPrint.pipe(take(1));
    const forkJoinUserCasino = forkJoin({ customersub, casinosub }).subscribe(({ customersub, casinosub }) => {
      this.dynPrintoutAppender('po_logo', '');
      this.dynPrintoutAppender(
        'po_header',
        this.translations.po_header + '  ' + customersub.customer.first_name + '\n\n'
      );
      this.dynPrintoutAppender('po_intro', this.translations.po_intro + '\n\n');
      this.dynPrintoutAppender('po_list_offers_intro', this.translations.po_list_offers_intro + '\n\n');

      for (const casino of casinosub) {
        this.dynPrintoutAppender('po_list_offers_name', casino.name + '\n');
        this.dynPrintoutAppender('po_list_offers_email', casino.internet_address + '\n\n');
      }
      this.dynPrintoutAppender('po_list_offers_outro', this.translations.po_list_offers_outro + '\n\n');

      this.dynPrintoutAppender('po_pwd_intro', this.translations.po_pwd_intro + '\n');

      this.dataShareService.currentPassword.subscribe(password => {
        this.dynPrintoutAppender('pwd', password + '\n\n');
      });

      this.dynPrintoutAppender('po_outro', this.translations.po_outro + '\n\n');

      this.dynPrintoutAppender('po_support', this.translations.po_support + '\n\n');
      this.dynPrintoutAppender('po_support_information', this.translations.po_support_information + '\n\n');

      this.dynPrintoutAppender('po_qr', this.translations.po_qr + '\n\n');

      let currentDate = new Date().toLocaleString('de-DE');
      const { id } = this.terminalDataService.getTerminalData();
      this.dynPrintoutAppender('po_terminal', 'Terminal (' + id + '): ' + currentDate + '\n\n');

      this.printDataBis();
    });
  }

  dynPrintoutPayin(transactionData: PayladoTransactionInfo): Printout {
    const { terminalId, transactionId, amount, dateTime, transactionType } = transactionData;
    this.dynPrintoutAppender('po_payin_logo', '');
    this.dynPrintoutAppender('po_payin_header', this.translations.po_payin_header + '\n\n');
    this.dynPrintoutAppender('po_payin_intro', this.translations.po_payin_intro + '\n\n');

    this.dynPrintoutAppender('po_timestamp', dateTime + '\n\n');
    this.dynPrintoutAppender('po_terminal', this.translations.po_terminal);

    this.dynPrintoutAppender('Terminal_ID', terminalId + '\n\n');
    this.dynPrintoutAppender('po_transaction_id', this.translations.po_transaction_id);
    this.dynPrintoutAppender('Transaction_ID', transactionId + '\n\n');
    this.dynPrintoutAppender('po_payout_amount', this.translations.po_payin_amount);
    this.dynPrintoutAppender('Amount_Value', amount + '\n\n');
    this.dynPrintoutAppender('po_paylado_support', this.translations.po_paylado_support + '\n\n');
    this.dynPrintoutAppender('po_qr', this.translations.po_qr + '\n\n');
    return this.printPayload;
  }

  dynPrintoutPayout(transactionData: PayladoTransactionInfo): Printout {
    const { terminalId, transactionId, amount, dateTime, transactionType } = transactionData;
    this.dynPrintoutAppender('po_payout_logo', '');
    this.dynPrintoutAppender('po_payout_header', this.translations.po_payout_header + '\n\n');
    this.dynPrintoutAppender('po_payout_intro', this.translations.po_payout_intro + '\n\n');

    this.dynPrintoutAppender('po_timestamp', dateTime + '\n\n');
    this.dynPrintoutAppender('po_terminal', this.translations.po_terminal);

    this.dynPrintoutAppender('Terminal_ID', terminalId + '\n\n');
    this.dynPrintoutAppender('po_transaction_id', this.translations.po_transaction_id);
    this.dynPrintoutAppender('Transaction_ID', transactionId + '\n\n');
    this.dynPrintoutAppender('po_payout_amount', this.translations.po_payout_amount);
    this.dynPrintoutAppender('Amount_Value', amount + '\n\n');
    this.dynPrintoutAppender('po_paylado_support', this.translations.po_paylado_support + '\n\n');
    this.dynPrintoutAppender('po_qr', this.translations.po_qr + '\n\n');
    return this.printPayload;
  }

  dynPrintoutAppender(tag: string, data: string): any {
    let printType: PrintType;
    let printAlignment: PrintAlignment;
    let printSizeHeight: PrintSizeHeight;
    let printSizeWidth: PrintSizeWidth;
    let printEmphasis: PrintEmphasis;

    const logo1Tags = ['po_logo'];
    const logo2Tags = [''];
    const logo3Tags = ['po_payout_logo', 'po_payin_logo'];
    const logo4Tags = [''];
    const logo5Tags = [''];

    const textTypeTags = [
      'po_header',
      'po_intro',
      'po_list_offers_name',
      'po_list_offers_email',
      'po_list_offers_outro',
      'po_pwd_intro',
      'po_outro',
      'po_support',
      'po_support_information',
      'po_payout_header',
      'po_payout_intro',
      'po_timestamp',
      'po_terminal',
      'Terminal_ID',
      'po_transaction_id',
      'Transaction_ID',
      'po_payout_amount',
      'Amount_Value',
      'po_paylado_support',
      'po_payin_header',
      'po_payin_intro',
      'po_payin_amount',
      'pwd'
    ];
    const qrTypeTags = ['po_qr'];

    const leftAlignTags = [
      'po_intro',
      'po_list_offers_intro',
      'list_offers',
      'po_list_offers_outro',
      'po_pwd_intro',
      'po_outro',
      'po_support',
      'po_list_offers_name',
      'po_list_offers_email',
      'po_terminal',
      'po_timestamp',
      'po_transaction_id',
      'po_payout_amount',
      'po_payin_amount',
      'po_payin_intro'
    ];
    const centerAlignTags = ['po_header', 'pwd', 'po_support_information', 'po_qr', 'po_footer'];
    const rightAlignTags = [];

    const sizeHeight1Tags = [
      'po_support_information',
      'po_list_offers_name',
      'po_terminal',
      'po_transaction_id',
      'po_payout_amount',
      'po_payin_amount'
    ];
    const sizeWidth1Tags = [
      'po_support_information',
      'po_list_offers_name',
      'po_terminal',
      'po_transaction_id',
      'po_payout_amount',
      'po_payin_amount'
    ];

    const sizeHeight2Tags = ['po_header', 'pwd', 'po_payout_header', 'po_payin_header'];
    const sizeWidth2Tags = ['po_header', 'pwd', 'po_payout_header', 'po_payin_header'];

    const textEmphasisTags = [
      'po_support_information',
      'po_list_offers_name',
      'po_terminal',
      'po_transaction_id',
      'po_payout_amount',
      'po_payin_amount'
    ];
    switch (true) {
      case logo1Tags.includes(tag):
        this.printPayload.logo = {
          number: 1,
          size: 'Normal'
        };
        break;
      case logo2Tags.includes(tag):
        this.printPayload.logo = {
          number: 2,
          size: 'Normal'
        };
        break;
      case logo3Tags.includes(tag):
        this.printPayload.logo = {
          number: 3,
          size: 'Normal'
        };
        break;
      case logo4Tags.includes(tag):
        this.printPayload.logo = {
          number: 4,
          size: 'Normal'
        };
        break;
      case logo5Tags.includes(tag):
        this.printPayload.logo = {
          number: 5,
          size: 'Normal'
        };
        break;
    }

    if (textTypeTags.includes(tag)) {
      printType = 'text';

      switch (true) {
        case sizeWidth1Tags.includes(tag):
          printSizeWidth = 1;
          break;
        case sizeWidth2Tags.includes(tag):
          printSizeWidth = 2;
          break;
        default:
          printSizeWidth = 0;
          break;
      }

      switch (true) {
        case sizeHeight1Tags.includes(tag):
          printSizeHeight = 1;
          break;
        case sizeHeight2Tags.includes(tag):
          printSizeHeight = 2;
          break;
        default:
          printSizeHeight = 0;
      }

      switch (true) {
        case leftAlignTags.includes(tag):
          printAlignment = 'Left';
          break;
        case centerAlignTags.includes(tag):
          printAlignment = 'Center';
          break;
        case rightAlignTags.includes(tag):
          printAlignment = 'Right';
          break;
        default:
          printAlignment = 'Center';
          break;
      }

      switch (true) {
        case textEmphasisTags.includes(tag):
          printEmphasis = true;
          break;
        default:
          printEmphasis = false;
      }

      this.printPayload.toPrint.push({
        type: printType,
        alignment: printAlignment,
        sizeHeight: printSizeHeight,
        sizeWidth: printSizeWidth,
        data: data,
        emphasis: printEmphasis
      });
    } else if (qrTypeTags.includes(tag)) {
      printType = 'qrcode';

      switch (true) {
        case leftAlignTags.includes(tag):
          printAlignment = 'Left';
          break;
        case centerAlignTags.includes(tag):
          printAlignment = 'Center';
          break;
        case rightAlignTags.includes(tag):
          printAlignment = 'Right';
          break;
        default:
          printAlignment = 'Center';
      }

      this.printPayload.toPrint.push({ type: printType, alignment: printAlignment, link: data });
    }
  }
}
