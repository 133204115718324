<div class="continue-btn">
  <button
    class="continue-btn__button"
    (click)="clickEvent.emit()"
    [disabled]="isDisabled"
    [ngClass]="{ 'inactive-btn': buttonInactive || isDisabled, 'spinner-btn': buttonSubmitting }"
  >
    <span [innerHTML]="textButton | translate"></span>
    <mat-spinner diameter="24" *ngIf="buttonSubmitting" class="spinner"></mat-spinner>
  </button>
</div>
